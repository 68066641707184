import { useEffect, useLayoutEffect, useRef } from "react"
import { BaseHTML } from "./BaseHTML"
import Matter from "matter-js"
import { TilesState } from "../TilesState"
import { useRafLoop } from "react-use"
import { round } from "../utils"
import { twMerge } from "tailwind-merge"

const CHAIN_LINKS = 4

const DIMENSIONS = {
  LINK: {
    width: 17,
    height: 27,
    url: env.themePath + "/assets/images/tiles/widgets/punching-bag/link.png",
  },
  // CONNECTOR: {
  //   width: 2,
  //   height: 2,
  //   url: '/assets/images/tiles/widgets/punching-bag/connector.png'
  // },
  BAG: {
    width: 106,
    height: 348,
    url: env.themePath + "/assets/images/tiles/widgets/punching-bag/bag.png",
  },
}

export const PunchingBag = () => {
  const outerRef = useRef<HTMLDivElement>(null)
  const ref = useRef<HTMLDivElement>(null)
  const chain = useRef<Matter.Composite>(
    Matter.Composites.stack(0, 0, CHAIN_LINKS, 1, 10, 10, (x: number, y: number, i: number) => {
      const last = i === CHAIN_LINKS - 1
      const w = last ? DIMENSIONS.BAG.height : DIMENSIONS.LINK.height
      const h = last ? DIMENSIONS.BAG.width : DIMENSIONS.LINK.width
      const options: Matter.IChamferableBodyDefinition = {
        // frictionAir: 0.1,
        density: last ? 0.001 : 0.002,
      }

      // if (!last) {
      options.isSensor = true //!last
      // } else {
      // if (last) {
      //   options.collisionFilter = {
      //     category: COLLISION_FILTERS.PUNCHING,
      //     mask: COLLISION_FILTERS.FIST
      //   }
      //   options.restitution = 0.8
      // }
      // }

      return Matter.Bodies.rectangle(x, y, w, h, options)
    })
  )
  const constraint = useRef<Matter.Constraint>(
    Matter.Constraint.create({
      bodyB: chain.current.bodies[0],
      pointB: { x: -25, y: 0 },
      pointA: { x: chain.current.bodies[0].position.x, y: chain.current.bodies[0].position.y },
      stiffness: 0.75,
      damping: 0.1,
    })
  )
  const chainComposite = useRef<Matter.Composite>(Matter.Composites.chain(chain.current, 0.5, 0, -0.5, 0, { stiffness: 0.85, damping: 0.1, length: 2 }))
  const chainLinks = [...Array(CHAIN_LINKS)].map(() => useRef<HTMLDivElement>(null))
  // const fist = useRef({
  //   position: { x: 0, y: 0, r: 1 },
  //   body: Matter.Bodies.circle(0, 0, 1, {
  //     collisionFilter: {
  //       category: COLLISION_FILTERS.FIST,
  //       mask: COLLISION_FILTERS.PUNCHING
  //     },
  //     restitution: 0.8,
  //     density: 0.01
  //   })
  // })
  // const fistRef = useRef<HTMLDivElement>(null)
  const worldPos = useRef({ x: 0, y: 0 })
  const prevPos = useRef({ x: 0, y: 0 })
  const visualPos = useRef([...Array(CHAIN_LINKS)].map(() => ({ x: 0, y: 0, r: 0 })))
  const spawned = useRef(false)

  const getWorldPos = () => {
    const pos = ref.current?.getBoundingClientRect()
    if (pos) {
      worldPos.current.x = pos.x
      worldPos.current.y = pos.y
    }
  }

  const repositionChain = () => {
    // move chain to match the actual widget position
    getWorldPos()
    const x = worldPos.current.x
    const y = worldPos.current.y

    constraint.current.pointA = { x, y }

    if (!spawned.current) {
      Matter.Composite.allBodies(chainComposite.current).forEach((b: Matter.Body) => {
        Matter.Body.setPosition(b, { x, y })
        Matter.Body.setSpeed(b, 0)
        // Matter.Body.setInertia(b, 0)
        Matter.Body.setVelocity(b, { x: 0, y: 0 })
        Matter.Body.setAngularVelocity(b, 0)
        // Matter.Body.setAngle(b, 0)
      })
      spawned.current = true
    }

    // does this help? not sure
    // Matter.Body.applyForce(chain.current!.bodies[0], chain.current!.bodies[0].position, { x: 0, y: -0.1 })

    // if (prevPos.current.x === 0 && prevPos.current.y === 0) {
    //   // first update
    //   chain.current!.bodies.forEach((b: Matter.Body) => {
    //     Matter.Body.setVelocity(b, { x: 0, y: 0 })
    //   })
    // }
    prevPos.current = { x, y }
  }

  const draw = () => {
    // if (!outerRef.current) return

    // // page position of innerRef
    // const rect = outerRef.current.getBoundingClientRect()
    // const iw = rect.width
    // const ih = rect.height
    // const ix = rect.left
    // const iy = rect.top

    chain.current.bodies.forEach((b, i) => {
      const pos = b.position
      const wp = worldPos.current
      const nextPos = visualPos.current[i + 1]
      const r1 = b.angle - Math.PI / 2
      const x = pos.x - wp.x
      const y = pos.y - wp.y
      const h = i === CHAIN_LINKS - 1 ? DIMENSIONS.BAG.height : DIMENSIONS.LINK.height

      const LERP_AMOUNT = 0.1

      visualPos.current[i].x += (x - visualPos.current[i].x) * LERP_AMOUNT
      visualPos.current[i].y += (y - visualPos.current[i].y) * LERP_AMOUNT
      visualPos.current[i].r += (r1 - visualPos.current[i].r) * LERP_AMOUNT

      const r = nextPos ? -Math.atan2(visualPos.current[i].x - nextPos.x, visualPos.current[i].y - nextPos.y - h / 2) : visualPos.current[i].r

      chainLinks[i].current!.style.transform = `translate(${round(visualPos.current[i].x)}px, ${round(visualPos.current[i].y)}px) rotate(${round(r)}rad)`
    })

    // const mx = TilesState.dragging.currentPos.x
    // const my = TilesState.dragging.currentPos.y
    // const fw = fistRef.current!.offsetWidth
    // const fx = clamp(mx - ix, 0, iw)
    // const fy = clamp(my - iy, 0, ih)

    // Matter.Body.scale(fist.current.body, (fw / 2) / fist.current.position.r, (fw / 2) / fist.current.position.r)
    // fist.current.position.r = fw / 2

    // // fistRef.current!.style.transform = `translate(${fx - (fw / 2)}px, ${fy - (fw / 2)}px)`
    // fistRef.current!.style.transform = `translate(${fist.current.body.position.x - worldPos.current.x + (iw / 2) - (fw / 2)}px, ${fist.current.body.position.y - worldPos.current.y - (fw / 2)}px)`
  }

  const onClick = (i: number) => {
    if (i < CHAIN_LINKS - 1) return
    const link = chain.current.bodies[i]
    // const direction = TilesState.dragging.currentPos.x > link.position.x ? 1 : -1
    const direction = Math.random() > 0.5 ? 1 : -1
    const force = (Math.random() * 3 + 2) * direction
    Matter.Body.applyForce(link, link.position, { x: force, y: -0.1 })
  }

  const render = () => {
    repositionChain()
    draw()
  }

  // const disableItemGravity = () => {
  //   disableGravity([fist.current.body])

  //   if (!outerRef.current) return

  //   // page position of innerRef
  //   const rect = outerRef.current.getBoundingClientRect()
  //   const iw = rect.width
  //   const ih = rect.height
  //   const ix = rect.left
  //   const iy = rect.top
  //   const mx = TilesState.dragging.currentPos.x
  //   const my = TilesState.dragging.currentPos.y
  //   const fw = fistRef.current!.offsetWidth
  //   const fx = clamp(mx - ix, 0, iw)
  //   const fy = clamp(my - iy, 0, ih)

  //   Matter.Body.setPosition(fist.current.body, { x: fx + worldPos.current.x - (iw / 2), y: fy + worldPos.current.y })
  // }

  useEffect(() => {
    Matter.Composite.add(chainComposite.current, constraint.current)
    Matter.Composite.add(TilesState.engine.world, [
      chainComposite.current,
      constraint.current,
      // fist.current.body
    ])

    return () => {
      Matter.Composite.clear(chainComposite.current, false, true)
      Matter.Composite.remove(
        TilesState.engine.world,
        [
          chainComposite.current,
          constraint.current,
          // fist.current.body
        ],
        true
      )
    }
  }, [])

  // useFrame(() => {
  //   repositionChain()
  //   draw()
  // }, -3)

  useRafLoop(() => {
    repositionChain()
    draw()
  })

  return (
    <>
      <BaseHTML title="Punching bag" />
      <div ref={outerRef} className="absolute top-0 left-0 w-full h-full">
        <div ref={ref} className="absolute top-0 left-0" style={{ marginLeft: PunchingBag.widgetConfig.width / 2 }}>
          {chainLinks.map((link, i) => {
            const last = i === CHAIN_LINKS - 1
            const size = last ? DIMENSIONS.BAG : DIMENSIONS.LINK
            return (
              <div
                key={i}
                ref={link}
                className={twMerge("absolute top-0 left-0 origin-center", last ? "pointer-events-auto cursor-pointer" : "")}
                style={{ width: size.width, height: size.height, marginLeft: -size.width / 2, marginTop: -size.height / 2 }}
                onClick={() => onClick(i)}
              >
                <img src={size.url} className="w-full h-full pointer-events-none" alt="" />
              </div>
            )
          })}
        </div>
        {/* <div ref={fistRef} className="absolute w-6 h-6 rounded-full bg-black top-0 left-0 pointer-events-none" /> */}
      </div>
    </>
  )
}

PunchingBag.widgetConfig = {
  id: "punching-bag",
  width: 287,
  height: 541,
  isHTML: true,
  alwaysOnTop: false,
  persist: false,
}
