import Matter from "matter-js"
import { useEffect, useLayoutEffect, useRef, useState } from "react"
import { useRafLoop } from "react-use"
import { TilesState } from "../TilesState"
import { BaseHTML } from "./BaseHTML"
import { disableGravity, round } from "../utils"
import { clamp } from "../utils"
import { COLLISION_FILTERS } from "../config"
import { Button } from "@components/controls/Button"

const MAX_BRICKS = 10

export const EmailBreaker = () => {
  const [started, setStarted] = useState(false)
  const innerRef = useRef<HTMLDivElement>(null)
  const walls = useRef({
    top: {
      body: Matter.Bodies.rectangle(0, 0, 1, 1, {
        isStatic: true,
        collisionFilter: { category: COLLISION_FILTERS.WALL, mask: COLLISION_FILTERS.BALL },
      }),
      bodyScale: {
        x: 1,
        y: 1,
      },
    },
    bottom: {
      body: Matter.Bodies.rectangle(0, 0, 1, 1, {
        isStatic: true,
        collisionFilter: { category: COLLISION_FILTERS.WALL, mask: COLLISION_FILTERS.BALL },
      }),
      bodyScale: {
        x: 1,
        y: 1,
      },
    },
    left: {
      body: Matter.Bodies.rectangle(0, 0, 1, 1, {
        isStatic: true,
        collisionFilter: { category: COLLISION_FILTERS.WALL, mask: COLLISION_FILTERS.BALL },
      }),
      bodyScale: {
        x: 1,
        y: 1,
      },
    },
    right: {
      body: Matter.Bodies.rectangle(0, 0, 1, 1, {
        isStatic: true,
        collisionFilter: { category: COLLISION_FILTERS.WALL, mask: COLLISION_FILTERS.BALL },
      }),
      bodyScale: {
        x: 1,
        y: 1,
      },
    },
  })
  const brickRefs = [...Array(MAX_BRICKS)].map(() => useRef<HTMLDivElement>(null))
  const bricks = useRef(
    [...Array(MAX_BRICKS)].map(() => ({
      life: 1,
      lifeLerp: 1,
      body: Matter.Bodies.rectangle(0, 0, 1, 1, {
        isStatic: true,
        collisionFilter: { category: COLLISION_FILTERS.BRICK, mask: COLLISION_FILTERS.BALL },
      }),
      bodyScale: {
        x: 1,
        y: 1,
      },
    })),
  )
  const bricksBroken = useRef(0)
  const ballRef = useRef<HTMLDivElement>(null)
  const ball = useRef({
    started: false,
    position: {
      x: 0,
      y: 0,
    },
    body: Matter.Bodies.circle(0, 0, 1, {
      friction: 0,
      frictionAir: 0,
      frictionStatic: 0,
      restitution: 1,
      collisionFilter: { category: COLLISION_FILTERS.BALL, mask: COLLISION_FILTERS.WALL | COLLISION_FILTERS.BRICK },
    }),
    bodyScale: {
      r: 1,
    },
  })
  const paddleRef = useRef<HTMLDivElement>(null)
  const paddle = useRef({
    position: {
      x: 0,
      y: 0,
    },
    body: Matter.Bodies.rectangle(0, 0, 1, 1, {
      collisionFilter: { category: COLLISION_FILTERS.WALL, mask: COLLISION_FILTERS.BALL },
    }),
    bodyScale: {
      x: 1,
      y: 1,
    },
  })

  const disableItemGravity = () => {
    if (!innerRef.current) return

    disableGravity([ball.current.body, paddle.current.body])

    if (ball.current.body.speed < 4) {
      Matter.Body.setSpeed(ball.current.body, 4)
    }

    if (!ball.current.started) {
      const w = innerRef.current.offsetWidth
      const h = innerRef.current.offsetHeight
      Matter.Body.setPosition(ball.current.body, { x: w / 2, y: h / 2 })
    }

    Matter.Body.setAngle(paddle.current.body, 0)
  }

  const reset = () => {
    bricksBroken.current = 0
    ball.current.started = false
    setStarted(false)
    bricks.current.forEach((b) => {
      b.life = 1
      b.lifeLerp = 1
      b.body.isSensor = false
    })
  }

  useEffect(() => {
    // @ts-ignore
    Matter.Resolver._restingThresh = 0.001

    Matter.World.add(TilesState.engine.world, [
      walls.current.top.body,
      walls.current.bottom.body,
      walls.current.left.body,
      walls.current.right.body,
      ...bricks.current.map((b) => b.body),
      ball.current.body,
      paddle.current.body,
    ])

    return () => {
      Matter.World.remove(TilesState.engine.world, [
        walls.current.top.body,
        walls.current.bottom.body,
        walls.current.left.body,
        walls.current.right.body,
        ...bricks.current.map((b) => b.body),
        ball.current.body,
        paddle.current.body,
      ])
    }
  }, [])

  useLayoutEffect(() => {
    Matter.Events.on(TilesState.engine, "beforeUpdate", disableItemGravity)

    return () => {
      Matter.Events.on(TilesState.engine, "beforeUpdate", disableItemGravity)
    }
  }, [])

  useRafLoop(() => {
    if (!innerRef.current) return

    const w = innerRef.current?.offsetWidth
    const h = innerRef.current?.offsetHeight

    if (w === 0 || h === 0) return

    // page position of innerRef
    const rect = innerRef.current.getBoundingClientRect()
    const iw = rect.width
    const ih = rect.height
    const ix = rect.left
    const iy = rect.top

    // update wall scales and positions
    const wallThickness = 100
    Matter.Body.scale(walls.current.top.body, w / walls.current.top.bodyScale.x, wallThickness / walls.current.top.bodyScale.y)
    walls.current.top.bodyScale.x = w
    walls.current.top.bodyScale.y = wallThickness
    Matter.Body.setPosition(walls.current.top.body, { x: w / 2, y: -wallThickness / 2 })
    Matter.Body.scale(walls.current.bottom.body, w / walls.current.bottom.bodyScale.x, wallThickness / walls.current.bottom.bodyScale.y)
    walls.current.bottom.bodyScale.x = w
    walls.current.bottom.bodyScale.y = wallThickness
    Matter.Body.setPosition(walls.current.bottom.body, { x: w / 2, y: h + wallThickness / 2 })
    Matter.Body.scale(walls.current.left.body, wallThickness / walls.current.left.bodyScale.x, h / walls.current.left.bodyScale.y)
    walls.current.left.bodyScale.x = wallThickness
    walls.current.left.bodyScale.y = h
    Matter.Body.setPosition(walls.current.left.body, { x: -wallThickness / 2, y: h / 2 })
    Matter.Body.scale(walls.current.right.body, wallThickness / walls.current.right.bodyScale.x, h / walls.current.right.bodyScale.y)
    walls.current.right.bodyScale.x = wallThickness
    walls.current.right.bodyScale.y = h
    Matter.Body.setPosition(walls.current.right.body, { x: w + wallThickness / 2, y: h / 2 })

    brickRefs.forEach((brickRef, i) => {
      if (!brickRef.current) return

      const brick = bricks.current[i]

      // scale the brick to match the div ref
      const bw = brickRef.current.offsetWidth
      const bh = brickRef.current.offsetHeight
      Matter.Body.scale(brick.body, bw / brick.bodyScale.x, bh / brick.bodyScale.y)
      brick.bodyScale.x = bw
      brick.bodyScale.y = bh

      const x = (w / 5) * (i % 5)
      const y = Math.floor(i / 5) * bh
      Matter.Body.setPosition(brick.body, { x: x + bw / 2, y: y + bh / 2 })
      brickRef.current.style.transform = `translate(${round(x)}px, ${round(y)}px)`

      // update brick life
      brick.lifeLerp += (brick.life - brick.lifeLerp) * 0.1
      brickRef.current.style.opacity = `${round(brick.lifeLerp)}`

      if (ball.current.started) {
        // brick collided with ball?
        if (Matter.Collision.collides(brick.body, ball.current.body)?.collided) {
          if (brick.life === 1) {
            brick.life = 0
            brick.body.isSensor = true
            bricksBroken.current++

            if (bricksBroken.current === MAX_BRICKS) {
              // THEY WIN omg!
              // reset bricks
              reset()
            }
          }
        }

        // ball collided with bottom wall?
        if (Matter.Collision.collides(walls.current.bottom.body, ball.current.body)?.collided) {
          reset()
        }
      }
    })

    if (!ballRef.current) return

    const br = ballRef.current.offsetWidth / 2
    Matter.Body.scale(ball.current.body, br / ball.current.bodyScale.r, br / ball.current.bodyScale.r)
    ball.current.bodyScale.r = br

    // ball position comes from matter
    // sync the div position with the matter position
    const ballPos = ball.current.body.position
    ball.current.position.x = ballPos.x
    ball.current.position.y = ballPos.y
    ballRef.current.style.transform = `translate(${round(ballPos.x - br / 2)}px, ${round(ballPos.y - br / 2)}px)`

    if (!paddleRef.current) return

    const pw = paddleRef.current.offsetWidth
    const ph = paddleRef.current.offsetHeight
    const pbh = 100
    Matter.Body.scale(paddle.current.body, pw / paddle.current.bodyScale.x, pbh / paddle.current.bodyScale.y)
    paddle.current.bodyScale.x = pw
    paddle.current.bodyScale.y = pbh

    const mx = TilesState.dragging.currentPos.x
    const my = TilesState.dragging.currentPos.y
    if (!ball.current.started) {
      paddle.current.position.x = w / 2 - pw / 2
      paddle.current.position.y = h - ph
    } else {
      const pscale = w / iw
      paddle.current.position.x = clamp((mx - ix) * pscale - pw / 2, 0, w - pw)
      paddle.current.position.y = h - ph
    }
    Matter.Body.setPosition(paddle.current.body, { x: paddle.current.position.x + pw / 2, y: paddle.current.position.y + pbh / 2 - ph / 2 })
    paddleRef.current.style.transform = `translate(${round(paddle.current.position.x)}px, ${round(paddle.current.position.y)}px)`
  })

  useEffect(() => {
    if (started) {
      Matter.Body.applyForce(ball.current.body, ball.current.body.position, { x: 0.001 * Math.random(), y: 0.002 })
      Matter.Body.setAngularSpeed(ball.current.body, 0.1)
      ball.current.started = true
    } else {
      Matter.Body.setVelocity(ball.current.body, { x: 0, y: 0 })
      ball.current.started = false
    }
  }, [started])

  return (
    <BaseHTML title="I hope this email finds you well">
      <div ref={innerRef} className="absolute w-full h-full top-0 left-0 pointer-events-none">
        {brickRefs.map((brick, i) => (
          <div ref={brick} key={i} className="w-[20%] absolute top-0 left-0">
            <img src={env.themePath + "assets/images/tiles/widgets/emailbreaker/email.svg"} className="w-full h-auto block p-[1px]" />
          </div>
        ))}

        <div ref={ballRef} className="w-4 h-4 bg-black absolute top-0 left-0 rounded-full" />

        <div ref={paddleRef} className="w-8 h-[11px] bg-black absolute top-0 left-0" />

        {!started && (
          <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 pointer-events-auto">
            <Button onClick={() => setStarted(true)}>Start</Button>
          </div>
        )}
      </div>
    </BaseHTML>
  )
}

EmailBreaker.widgetConfig = {
  id: "email-breaker",
  width: 287,
  height: 287,
  isHTML: true,
  alwaysOnTop: false,
  persist: false
}
