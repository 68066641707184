import { RefObject, useEffect, useRef } from "react"
import { TileType } from "../Tile"
import { MAX_TILE_HEIGHT, MAX_TILE_WIDTH } from "../config"
import { coverFit } from "../utils"
import { Link } from "eddev/routing"
import { TilesState } from "../TilesState"
import { RouteFlipped } from "@components/motion/RouteFlipped"

const parseSrcSet = (url: string) => {
  // sometimes images have spaces in their filenames and it breaks the srcset
  const srcs = url.split(",").map((src) => {
    const dots = src.trim().split(".")
    let str = dots.slice(0, -1).join(".")
    return `${str.replace(/ /g, "%20")}.${dots[dots.length - 1]}`
  })
  return srcs.join(", ")
}

export const Image = ({ src, url, tile, flipId }: { src: string; url?: string | null; flipId?: string; tile: RefObject<TileType> }) => {
  const imageRef = useRef<HTMLImageElement>(null)

  useEffect(() => {
    if (!imageRef.current || !tile.current) return

    const onLoad = () => {
      if (!imageRef.current?.naturalWidth || !imageRef.current?.naturalHeight) return
      const { naturalWidth, naturalHeight } = imageRef.current!
      const w = naturalWidth
      const h = naturalHeight
      const { width: sw, height: sh } = coverFit(w, h, TilesState.maxTileSize.width, TilesState.maxTileSize.height)

      tile.current?.setDefaultScale(sw, sh)
      tile.current?.setScale(sw, sh)
    }

    imageRef.current.addEventListener("load", onLoad)

    return () => {
      imageRef.current?.removeEventListener("load", onLoad)
    }
  }, [imageRef.current, tile.current])

  return (
    <div className="relative group pointer-events-auto">
      <a
        href={url!}
        draggable={false}
        onClick={(e) => {
          if (url && !e.shiftKey && (TilesState.dragging.maybeClicking || !TilesState.canSelect)) {
            TilesState.router?.handleClickEvent(e, url)
          } else {
            e.preventDefault()
          }
        }}
      >
        <RouteFlipped flipId={flipId!}>
          <img ref={imageRef} srcSet={parseSrcSet(src)} className="pointer-events-none" />
        </RouteFlipped>
      </a>
    </div>
  )
}

Image.widgetConfig = {
  id: "image",
  width: 181,
  height: 226,
  isHTML: true,
  alwaysOnTop: false,
  persist: false,
}
