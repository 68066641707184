import { BaseHTML } from "./BaseHTML"
import { Button } from "@components/controls/Button"
import { TilesState } from "../TilesState"

export const Finalists = () => {
  return (
    <BaseHTML title="AGDA AWARDS">
      <div className="w-full h-full flex justify-between flex-col">
        <p className="type-title-l-medium">
          Finalists<br />
          <span className="text-grey">Announced</span>
        </p>
        <Button onClick={(e) => {
          TilesState.router?.handleClickEvent(e, '/awards')
        }} className="w-fit pointer-events-auto">View here</Button>
      </div>
    </BaseHTML>
  )
}

Finalists.widgetConfig = {
  id: "finalists",
  width: 287,
  height: 198,
  isHTML: true,
  alwaysOnTop: true,
  persist: true
}