import Matter from "matter-js"
import { OUTLINE_PADDING } from "./config"
import { MutableRefObject } from "react"
import { TilesState } from "./TilesState"
import { TileType } from "./Tile"

// convert to three position
export const fromTopLeft = (x: number, y: number) => {
  return {
    x: x - window.innerWidth / 2,
    y: -y + window.innerHeight / 2
  }
}

// convert from three position
export const toTopLeft = (x: number, y: number) => {
  return {
    x: x + window.innerWidth / 2,
    y: -y + window.innerHeight / 2
  }
}

export const clamp = (num: number, min: number, max: number) => {
  if (min === undefined) min = 0
  if (max === undefined) max = 1
  if (num < min) return min
  if (num > max) return max
  return num
}

export const round = (n: number) => {
  return Math.round(n * 1000) / 1000
}

export const lerp = (unitary: number, num1: number, num2: number) => {
  return num1 + unitary * (num2 - num1)
}

export const norm = (value: number, min: number, max: number) => {
  return (value - min) / (max - min)
}

export const map = (value: number, sourceMin: number, sourceMax: number, destMin: number, destMax: number, clamped: boolean) => {
  value = lerp(norm(value, sourceMin, sourceMax), destMin, destMax)
  if (clamped) value = clamp(value, destMin, destMax)
  return value
}

export const getOutlinePositions = (ref: MutableRefObject<TileType | null>) => {
  const scale = ref.current?.getScale() || { x: 1, y: 1 }
  const position = ref.current?.getPosition() || { x: 0, y: 0 }
  const ox = scale.x / 2
  const oy = scale.y / 2
  const { x: bx, y: by } = { x: position.x - ox, y: position.y - oy }
  const { x: tlX, y: tlY } = { x: bx - OUTLINE_PADDING, y: by - OUTLINE_PADDING }
  const { x: trX, y: trY } = { x: bx + scale.x + OUTLINE_PADDING, y: by - OUTLINE_PADDING }
  const { x: brX, y: brY } = { x: bx + scale.x + OUTLINE_PADDING, y: by + scale.y + OUTLINE_PADDING }
  const { x: blX, y: blY } = { x: bx - OUTLINE_PADDING, y: by + scale.y + OUTLINE_PADDING }
  return { tlX, tlY, trX, trY, brX, brY, blX, blY }
}

// export const getCompositionOutlinePositions = (composite: Matter.Composite) => {
//   let tlX1 = Infinity
//   let tlY1 = Infinity
//   let trX1 = -Infinity
//   let trY1 = Infinity
//   let blX1 = Infinity
//   let blY1 = -Infinity
//   let brX1 = -Infinity
//   let brY1 = -Infinity
//   composite.bodies.forEach(body => {
//     body.vertices.forEach(v => {
//       tlX1 = Math.min(tlX1, v.x)
//       tlY1 = Math.min(tlY1, v.y)
//       trX1 = Math.max(trX1, v.x)
//       trY1 = Math.min(trY1, v.y)
//       blX1 = Math.min(blX1, v.x)
//       blY1 = Math.max(blY1, v.y)
//       brX1 = Math.max(brX1, v.x)
//       brY1 = Math.max(brY1, v.y)
//     })
//   })
//   const { x: tlX, y: tlY } = fromTopLeft(tlX1 - OUTLINE_PADDING, tlY1 - OUTLINE_PADDING)
//   const { x: trX, y: trY } = fromTopLeft(trX1 + OUTLINE_PADDING, trY1 - OUTLINE_PADDING)
//   const { x: blX, y: blY } = fromTopLeft(blX1 - OUTLINE_PADDING, blY1 + OUTLINE_PADDING)
//   const { x: brX, y: brY } = fromTopLeft(brX1 + OUTLINE_PADDING, brY1 + OUTLINE_PADDING)
//   return { tlX, tlY, trX, trY, brX, brY, blX, blY }
// }

export const getCompositionOutlinePositions = (composite: Matter.Composite) => {
  let tlX1 = Infinity
  let tlY1 = Infinity
  let trX1 = -Infinity
  let trY1 = Infinity
  let blX1 = Infinity
  let blY1 = -Infinity
  let brX1 = -Infinity
  let brY1 = -Infinity
  let failed = false
  composite.bodies.forEach(body => {
    const tile = TilesState.tileRefs.find(t => t?.ref?.getBody() === body)
    if (!tile) {
      failed = true
      return
    }
    const pos = tile.ref?.getPosition() || { x: 0, y: 0 } //toTopLeft(tile.ref!.position.x, tile.ref!.position.y)
    const scale = tile.ref?.getScale() || { x: 1, y: 1 }
    const hw = scale.x / 2
    const hh = scale.y / 2
    tlX1 = Math.min(tlX1, pos.x - hw)
    tlY1 = Math.min(tlY1, pos.y - hh)
    trX1 = Math.max(trX1, pos.x - hw + scale.x)
    trY1 = Math.min(trY1, pos.y - hh)
    blX1 = Math.min(blX1, pos.x - hw)
    blY1 = Math.max(blY1, pos.y - hh + scale.y)
    brX1 = Math.max(brX1, pos.x - hw + scale.x)
    brY1 = Math.max(brY1, pos.y - hh + scale.y)
  })
  if (failed || composite.bodies.length === 0) {
    return { tlX: 0, tlY: 0, trX: 1, trY: 1, brX: 1, brY: 1, blX: 0, blY: 1 }
  }
  const { x: tlX, y: tlY } = { x: tlX1 - OUTLINE_PADDING, y: tlY1 - OUTLINE_PADDING }
  const { x: trX, y: trY } = { x: trX1 + OUTLINE_PADDING, y: trY1 - OUTLINE_PADDING }
  const { x: blX, y: blY } = { x: blX1 - OUTLINE_PADDING, y: blY1 + OUTLINE_PADDING }
  const { x: brX, y: brY } = { x: brX1 + OUTLINE_PADDING, y: brY1 + OUTLINE_PADDING }
  return { tlX, tlY, trX, trY, brX, brY, blX, blY }
}

export const disableGravity = (bb: Matter.Body[]) => {
  const gravity = TilesState.engine.gravity
  bb.forEach(b => {
    Matter.Body.applyForce(b, b.position, {
      x: -gravity.x * gravity.scale * b.mass,
      y: -gravity.y * gravity.scale * b.mass
    })
  })
}

export const coverFit = (width: number, height: number, containerWidth: number, containerHeight: number) => {
  let sw = width
  let sh = height
  const ir = height / width
  const tr = containerHeight / containerWidth
  if (ir > tr) {
    sh = containerHeight
    sw = sh / ir
  } else {
    sw = containerWidth
    sh = sw * ir
  }
  return { width: sw, height: sh }
}