import { forwardRef, useEffect, useRef, MutableRefObject, RefObject, useImperativeHandle, useLayoutEffect } from "react"
import { COLLISION_FILTERS } from "./config"
import Matter from "matter-js"
import { disableGravity, getCompositionOutlinePositions, getOutlinePositions } from "./utils"
import { TileType } from "./Tile"
import { TilesState } from "./TilesState"
import { useRafLoop } from "react-use"
import { twMerge } from "tailwind-merge"

export type ResizeCornerType = HTMLDivElement & {
  updatePosition: (ref: MutableRefObject<HTMLDivElement | null>) => void
}

type ResizeCornerProps = {
  tile?: RefObject<TileType>
  corner: "tl" | "tr" | "br" | "bl"
  composite?: boolean
  visible?: boolean
}

export const ResizeCorner = forwardRef<HTMLDivElement, ResizeCornerProps>((props, outerRef) => {
  outerRef = outerRef as unknown as MutableRefObject<ResizeCornerType>
  const ref = useRef<HTMLDivElement>(null)
  const { tile, corner, visible, composite } = props
  // base pixel size of the corner body
  const scaleBase = 7
  const scale = useRef(1)
  const boxDimensions = useRef({ width: 0, height: 0 })
  const body = useRef<Matter.Body>(
    Matter.Bodies.rectangle(
      0,
      0,
      // make corner body 3x larger for nicer mouse interaction
      // since body is purely for interaction and not visible
      scaleBase * 3,
      scaleBase * 3,
      {
        // disable physical collisions but still trigger collision events
        isSensor: true,
        // only interact with mouse constraint
        collisionFilter: {
          category: COLLISION_FILTERS.INTERACT,
          mask: COLLISION_FILTERS.MOUSE,
        },
      }
    )
  )
  const interacting = useRef(false)

  useImperativeHandle(
    outerRef,
    () =>
      Object.assign(ref.current!, {
        updatePosition,
      }),
    []
  )

  const updatePosition = () => {
    const { tlX, tlY, trX, trY, brX, brY, blX, blY } = tile?.current ? getOutlinePositions(tile) : getCompositionOutlinePositions(TilesState.dragging.selectionComposite)

    let x = 0,
      y = 0

    switch (corner) {
      case "tl":
        x = tlX
        y = tlY
        break
      case "tr":
        x = trX
        y = trY
        break
      case "br":
        x = brX
        y = brY
        break
      case "bl":
        x = blX
        y = blY
        break
    }

    const scaleX = scale.current
    const scaleY = scale.current

    if (ref.current) {
      ref.current.style.transform = `translate(${x - scaleBase / 2}px, ${y - scaleBase / 2}px) scale(${scaleX}, ${scaleY})`
    }

    boxDimensions.current = { width: trX - tlX, height: blY - tlY }

    // sync body position with r3f sprite
    Matter.Body.setPosition(body.current, { x, y })
  }

  const disableTileGravity = () => {
    if (!body.current) return
    disableGravity([body.current])
  }

  if (env.client) {
    useLayoutEffect(() => {
      Matter.Events.on(TilesState.engine, "beforeUpdate", disableTileGravity)
      if (composite) {
        TilesState.dragging.selectionCompositeCornerBodies.push(body.current)
      }

      return () => {
        Matter.Events.off(TilesState.engine, "beforeUpdate", disableTileGravity)
        if (composite) {
          TilesState.dragging.selectionCompositeCornerBodies = TilesState.dragging.selectionCompositeCornerBodies.filter((b) => b !== body.current)
        }
        Matter.World.remove(TilesState.engine.world, [body.current], true)
      }
    }, [])
  }

  useEffect(() => {
    if (visible) {
      Matter.World.add(TilesState.engine.world, [body.current])
    } else {
      Matter.World.remove(TilesState.engine.world, [body.current])
    }
  }, [visible])

  const resizeTile = (tile: TileType, pos?: boolean) => {
    tile.startResize()
    const { startPos, currentPos } = TilesState.dragging
    const dx = currentPos.x - startPos.x
    const { width, height } = tile.getResizeDimensions()
    const sideMod = corner === "tl" || corner === "bl" ? -1 : 1
    const d = (width + dx * sideMod) / width
    tile.setScale(width * d, height * d)

    if (pos) {
      // move the tile closer to the center point of the resize
      const resizePos = tile.getResizePosition()
      const { x, y } = resizePos
      const { x: cx, y: cy } = currentPos
      const { x: sx, y: sy } = startPos
      const diffX = sx - cx
      const diffY = sy - cy
      tile.setPosition(x - diffX / 2, y - diffY / 2)
    }
  }

  useRafLoop(() => {
    updatePosition()

    if (TilesState.dragging.corner === body.current) {
      // scale it up visually
      scale.current = 1.5
      interacting.current = true
      const tileBody = tile?.current?.getBody()

      if (tile && tileBody && TilesState.dragging.body.length === 1) {
        // dragging a tile

        // always make sure the tile body attached to this corner is the one we're dragging
        if (TilesState.dragging.bodyTimer) {
          clearTimeout(TilesState.dragging.bodyTimer)
        }
        TilesState.dragging.body = [tileBody]

        // // okay, we're dragging the corner to resize the tile.
        // // adjust tile scale based on which corner, and how much we've moved
        // // currently just a centre/alt scale
        // const { startPos, currentPos } = TilesState.dragging
        // const { width, height } = tile.current!.getResizeDimensions()
        // const sideMod = corner === 'tl' || corner === 'bl' ? -1 : 1
        // const dx = currentPos.x - startPos.x
        // const dy = currentPos.y - startPos.y
        // const d = (width + dx * sideMod) / width
        // // const d = (height - dy) / height
        // tile.current!.setScale(width * d, height * d)

        resizeTile(tile.current!)
      } else if (TilesState.dragging.body.length > 1 && composite) {
        // dragging a composite of tiles

        // const { startPos, currentPos } = TilesState.dragging
        // const { width, height } = boxDimensions.current
        // const sideMod = corner === 'tl' || corner === 'bl' ? -1 : 1
        // const dx = currentPos.x - startPos.x
        // const dy = currentPos.y - startPos.y
        // const d = (width + dx * sideMod) / width

        // // Matter.Composite.scale(composite.current!, d, d, { x: body.current.position.x, y: body.current.position.y })

        // get all the tiles in the composite
        const tiles = TilesState.tileRefs.filter((t) => t?.ref?.getBody() && TilesState.dragging.selectionComposite.bodies.includes(t?.ref.getBody()))

        tiles.forEach((t) => {
          if (t.ref) {
            resizeTile(t.ref)
          }
        })
      }

      // basically mouseUp on this corner
    } else {
      // save tile resizeDimensions so next time we resize, we start from that point
      if (tile && interacting.current) {
        tile.current?.endResize()
        interacting.current = false
      } else if (!tile && interacting.current) {
        const tiles = TilesState.tileRefs.filter((t) => t?.ref?.getBody() && TilesState.dragging.selectionComposite.bodies.includes(t?.ref.getBody()))

        tiles.forEach((t) => {
          t.ref?.endResize()
        })

        interacting.current = false
      }

      scale.current = 1
    }
  })

  return (
    <div
      ref={ref}
      className={twMerge(
        "absolute left-0 top-0 bg-black opacity-0",
        corner === "tl" ? "cursor-nwse-resize" : "",
        corner === "tr" ? "cursor-nesw-resize" : "",
        corner === "br" ? "cursor-nwse-resize" : "",
        corner === "bl" ? "cursor-nesw-resize" : "",
        visible ? "pointer-events-auto" : ""
      )}
      style={{ width: `${scaleBase}px`, height: `${scaleBase}px` }}
    />
  )
})
