import { forwardRef, useEffect, useImperativeHandle, useRef, useState, MutableRefObject, useLayoutEffect } from "react"
import { useRafLoop, useWindowSize } from "react-use"
import { COLLISION_FILTERS, DEBUG, findNextPosition, GET_REM_SCALE, LERP_STRENGTH, OUTLINE_PADDING } from "./config"
import { disableGravity, round } from "./utils"
import { ResizeCorner, ResizeCornerType } from "./ResizeCorner"
import Matter from "matter-js"
import { TilesState } from "./TilesState"
import { TileOutline } from "./TileOutline"
import { useSnapshot } from "valtio"

export type TileType = HTMLDivElement & {
  // some extra functions that can be called on this tile's ref
  // provided by useImperativeHandle below
  getPosition: () => { x: number; y: number }
  setPosition: (x: number, y: number) => void
  setDefaultScale: (x?: number, y?: number) => void
  setScale: (x: number, y: number) => void
  getScale: () => { x: number; y: number }
  getResizeDimensions: () => { width: number; height: number }
  setResizeDimensions: (width: number, height: number) => void
  getResizePosition: () => { x: number; y: number }
  startResize: () => void
  endResize: () => void
  getBody: () => Matter.Body
  setRenderOrder: (i: number) => void
  getRenderOrder: () => number
  setAsSelected: (s: boolean) => void
  refresh: (w?: number, h?: number) => void
  sunset: (a: boolean) => void
  getIsSunset: () => boolean
  setLife: (l: number) => void
  getIndex: () => number
  getArrangement: () => { col: number; row: number }
  getArrangementPosition: () => { x: number; y: number; rx: number; ry: number }
}

type TileProps = {
  url?: string
  index: number
  slot: number
  renderOrder: number
  children?: React.ReactNode
  width?: number
  height?: number
  isHTML?: boolean
}

const EMPTY_TEXTURE = env.themePath + "assets/images/tiles/blank.png"

export const Tile = forwardRef<TileType, TileProps>(({ url, index, slot, renderOrder: rOrder, children, width: tileWidth, height: tileHeight, isHTML }: TileProps, outerRef) => {
  outerRef = outerRef as unknown as MutableRefObject<TileType>
  const { width, height } = useWindowSize()
  // the actual scale of the outer body (orange) (used internally)
  const scale = useRef({ x: 1, y: 1 })
  const position = useRef({ x: 0, y: 0 })
  // the scale of the inner body (red) (used internally) (scaled by TILE_OVERLAP_BOUNDARY_SCALE)
  const defaultScale = useRef({ x: tileWidth || 1, y: tileHeight || 1 })
  const meshScale = useRef({ x: defaultScale.current.x, y: defaultScale.current.y })
  const bodyScale = useRef({ x: 1, y: 1 })
  // the lerped scale used to size the r3f mesh based on the scales above
  const visualScale = useRef({ x: 100, y: 100 })
  // used when resizing the tile, to start from that saved point next resize
  const resizeDimensions = useRef({ width: 0, height: 0 })
  const resizePosition = useRef({ x: 0, y: 0 })
  const resizing = useRef(false)
  // todo: remove (not really used)
  const pos = useRef({ x: 0, y: 0 })
  // used to show/hide selection box and resize corners
  const [selected, setSelected] = useState(false)
  const [visible, setVisible] = useState(true)
  const visibleTimer = useRef(0)
  const life = useRef(1)
  const lifeLerp = useRef(1)
  const ref = useRef<HTMLDivElement>(null)
  const dotTLRef = useRef<ResizeCornerType>(null)
  const dotTRRef = useRef<ResizeCornerType>(null)
  const dotBRRef = useRef<ResizeCornerType>(null)
  const dotBLRef = useRef<ResizeCornerType>(null)
  const contentRef = useRef<HTMLDivElement>(null)
  const htmlZIndex = useRef(0)
  const outlineRef = useRef<HTMLDivElement>(null)
  // inner body (red)
  const body = useRef<Matter.Body>(
    Matter.Bodies.rectangle(pos.current.x, pos.current.y, scale.current.x, scale.current.y, {
      frictionAir: 0.15,
      collisionFilter: { category: COLLISION_FILTERS.GENERAL, mask: COLLISION_FILTERS.MOUSE | COLLISION_FILTERS.GENERAL },
    })
  )
  // outer body (orange)
  const mouseBody = useRef<Matter.Body>(
    Matter.Bodies.rectangle(pos.current.x, pos.current.y, scale.current.x, scale.current.y, {
      frictionAir: 0.15,
      collisionFilter: { category: COLLISION_FILTERS.INTERACT, mask: COLLISION_FILTERS.MOUSE },
    })
  )
  // zIndex
  const renderOrder = useRef(rOrder)
  const [isSunset, setIsSunset] = useState(false)
  const arrangement = useRef({ col: 0, row: 0 })
  const state = useSnapshot(TilesState)

  // this accomplishes two things:
  // 1. make the forwarded ref of this tile match the inner ref, so outerRef==ref
  // 2. expose these extra methods on the ref for use outside
  useImperativeHandle(
    outerRef,
    () =>
      Object.assign(ref.current!, {
        getPosition,
        setPosition,
        setDefaultScale,
        setScale,
        getScale,
        getResizeDimensions,
        setResizeDimensions,
        getResizePosition,
        startResize,
        endResize,
        getBody,
        setRenderOrder,
        getRenderOrder,
        setAsSelected,
        refresh,
        sunset,
        getIsSunset,
        setLife,
        getIndex,
        getArrangement,
        getArrangementPosition,
      }),
    []
  )

  const setRenderOrder = (i: number) => {
    // console.log('SET RENDER ORDER', index, i)
    renderOrder.current = i
  }

  const getRenderOrder = () => {
    return renderOrder.current
  }

  const setAsSelected = (s: boolean) => {
    setSelected(s)
    if (s) {
      TilesState.selected = -1
      TilesState.selected = slot
    }
  }

  const setDefaultScale = (x?: number, y?: number) => {
    if (x && y) {
      defaultScale.current.x = x
      defaultScale.current.y = y
    }
  }

  const setScale = (x: number, y: number) => {
    // don't let the scale go below 20% of the default scale
    meshScale.current.x = Math.max(x, defaultScale.current.x * 0.2)
    meshScale.current.y = Math.max(y, defaultScale.current.y * 0.2)
  }

  const getScale = () => {
    return { x: meshScale.current.x, y: meshScale.current.y }
  }

  const setResizeDimensions = (width: number, height: number) => {
    resizeDimensions.current.width = width
    resizeDimensions.current.height = height
  }

  const getResizeDimensions = () => {
    // if resizeDimensions haven't been set, set as the current dimensions
    if (resizeDimensions.current.width === 0 || resizeDimensions.current.height === 0) {
      const { x, y } = getScale()
      setResizeDimensions(x, y)
    }
    return { width: resizeDimensions.current.width, height: resizeDimensions.current.height }
  }

  const getResizePosition = () => {
    return { x: resizePosition.current.x, y: resizePosition.current.y }
  }

  const startResize = () => {
    if (resizing.current) return
    resizePosition.current.x = mouseBody.current.position.x
    resizePosition.current.y = mouseBody.current.position.y
    resizing.current = true
  }

  const endResize = () => {
    if (!resizing.current) return
    setResizeDimensions(meshScale.current.x, meshScale.current.y)
    resizing.current = false
  }

  const getPosition = () => {
    return { x: position.current.x, y: position.current.y }
  }

  const setPosition = (x: number, y: number) => {
    Matter.Body.setPosition(body.current, { x, y })
  }

  const getBody = () => {
    return mouseBody.current
  }

  const refresh = (w?: number, h?: number) => {
    if (w && h && (w !== defaultScale.current.x || h !== defaultScale.current.y)) {
      setDefaultScale(w, h)
      if (DEBUG) {
        console.log("refresh", defaultScale.current)
      }
      // re-set the tile dimensions
      // and anything else that needs to be refreshed
      setScale(defaultScale.current.x, defaultScale.current.y)
      setResizeDimensions(0, 0)
    }
  }

  const sunset = (a: boolean) => {
    setIsSunset(a)
  }

  const getIsSunset = () => {
    return isSunset
  }

  const setLife = (l: number) => {
    life.current = l
  }

  const getIndex = () => {
    return index
  }

  const getArrangement = () => {
    return arrangement.current
  }

  const getArrangementPosition = () => {
    const { col, row } = findNextPosition(index, TilesState.arrangement)
    const cols = TilesState.arrangement[0].length
    const cx = width / cols
    const cy = height / cols
    // add a bit of randomness
    const rx = TilesState.canSelect ? Math.random() * cx : 0
    const ry = TilesState.canSelect ? Math.random() * cy : 0
    const x = cx * col + cx / 3
    const y = cy * (row % cols) + cy / 3
    if (DEBUG) {
      console.log("INITIAL TILE POSITION", index, x, y)
    }
    arrangement.current = { col, row }
    return { x, y, rx, ry }
  }

  const render = (time: number) => {
    if (TilesState.mode === "playground") {
      const safezone = TilesState.safezone
      const minX = safezone.left + visualScale.current.x / 2
      const maxX = safezone.right - visualScale.current.x / 2
      const minY = safezone.top + visualScale.current.y / 2
      const maxY = safezone.bottom - visualScale.current.y / 2
      // // keep the body inside the walls 👻
      if (body.current.position.x < minX) {
        Matter.Body.setPosition(body.current, { x: minX, y: body.current.position.y })
      }
      if (body.current.position.x > maxX) {
        Matter.Body.setPosition(body.current, { x: maxX, y: body.current.position.y })
      }
      if (body.current.position.y < minY) {
        Matter.Body.setPosition(body.current, { x: body.current.position.x, y: minY })
      }
      if (body.current.position.y > maxY) {
        Matter.Body.setPosition(body.current, { x: body.current.position.x, y: maxY })
      }
    }

    // prevent automatic rotation from collisions
    Matter.Body.setAngularVelocity(body.current, 0)
    Matter.Body.setAngularVelocity(mouseBody.current, 0)

    // // give some wiggle if canSelect is false
    // if (!TilesState.canSelect) {
    //   const x = Math.sin(time * 0.1 * index) * 0.1 * visualScale.current.x * 0.005
    //   const y = Math.cos(time * 0.1 * index) * 0.1 * visualScale.current.y * 0.005
    //   Matter.Body.setPosition(body.current, { x: body.current.position.x + x, y: body.current.position.y + y })
    // }

    // keep the mesh position in sync with the body
    const { x, y } = body.current.position
    if (ref.current) {
      if (TilesState.mode === "playground") {
        // only lerp the position if we're in playground mode
        position.current.x += (x - position.current.x) * LERP_STRENGTH.POSITION
        position.current.y += (y - position.current.y) * LERP_STRENGTH.POSITION
      } else {
        // otherwise position is set directly
        position.current.x = x
        position.current.y = y
      }
    }

    if (
      (TilesState.dragging.body.includes(mouseBody.current) || TilesState.dragging.body.includes(body.current)) &&
      TilesState.dragging.body.length === 1 &&
      !selected &&
      !TilesState.dragging.maybeClicking
    ) {
      setAsSelected(true)
    } else if (!((TilesState.dragging.body.includes(mouseBody.current) || TilesState.dragging.body.includes(body.current)) && TilesState.dragging.body.length === 1) && selected) {
      setAsSelected(false)
    }

    if (TilesState.mode === "playground") {
      // if the body being dragged is the outer (orange) body
      if (TilesState.dragging.body.includes(mouseBody.current)) {
        // sync inner (red) body position with outer (orange) mouse body
        Matter.Body.setPosition(body.current, mouseBody.current.position)
        // otherwise if we're dragging the inner (red) body
      } else {
        // sync outer (orange) mouse body position with inner (red) body
        Matter.Body.setPosition(mouseBody.current, body.current.position)
      }
    }
  }

  const resize = () => {
    const RS = GET_REM_SCALE()

    lifeLerp.current += (life.current - lifeLerp.current) * 0.1

    const LS = lifeLerp.current

    // resize the inner (red) body to match the mesh but scaled
    const bsx = (meshScale.current.x || 1) * TilesState.overlapBoundaryScale * RS
    const bsy = (meshScale.current.y || 1) * TilesState.overlapBoundaryScale * RS
    if (bodyScale.current.x !== bsx || bodyScale.current.y !== bsy) {
      // Matter.js bodies scale by an amount and aren't "set" to a literal value like a mesh
      // so figure out by how much to scale them
      const sw = round(bsx / bodyScale.current.x)
      const sh = round(bsy / bodyScale.current.y)
      Matter.Body.scale(body.current, sw, sh)
      bodyScale.current.x = bsx
      bodyScale.current.y = bsy
    }

    // resize the outer (orange) mouse body to match the mesh
    const sx = (meshScale.current.x || 1) * RS
    const sy = (meshScale.current.y || 1) * RS
    if (scale.current.x !== sx || scale.current.y !== sy) {
      const ssw = round(sx / scale.current.x)
      const ssh = round(sy / scale.current.y)
      Matter.Body.scale(mouseBody.current, ssw, ssh)
      scale.current.x = sx
      scale.current.y = sy
    }

    // resize the mesh to match visualScale
    visualScale.current.x += (meshScale.current.x * RS * LS - visualScale.current.x) * LERP_STRENGTH.SCALE
    visualScale.current.y += (meshScale.current.y * RS * LS - visualScale.current.y) * LERP_STRENGTH.SCALE
  }

  const updateContent = () => {
    htmlZIndex.current = 500 - renderOrder.current

    if (contentRef.current) {
      contentRef.current.style.width = `${defaultScale.current.x}px`
      contentRef.current.style.height = `${defaultScale.current.y}px`
      contentRef.current.style.marginLeft = `-${defaultScale.current.x / 2}px`
      contentRef.current.style.marginTop = `-${defaultScale.current.y / 2}px`
      contentRef.current.style.transform = `translate(${position.current.x}px, ${position.current.y}px) scale(${round(visualScale.current.x / defaultScale.current.x)}, ${round(
        visualScale.current.y / defaultScale.current.y
      )})`
      contentRef.current.style.opacity = lifeLerp.current.toString()
      contentRef.current.style.zIndex = htmlZIndex.current.toString()
    }
  }

  const updateOutline = () => {
    const padding = OUTLINE_PADDING * 2
    if (outlineRef.current) {
      outlineRef.current.style.width = `${visualScale.current.x + padding}px`
      outlineRef.current.style.height = `${visualScale.current.y + padding}px`
      outlineRef.current.style.marginLeft = `-${(visualScale.current.x + padding) / 2}px`
      outlineRef.current.style.marginTop = `-${(visualScale.current.y + padding) / 2}px`
      outlineRef.current.style.transform = `translate(${position.current.x}px, ${position.current.y}px)`
      outlineRef.current.style.zIndex = htmlZIndex.current.toString()
    }
  }

  const disableTileGravity = () => {
    if (!visible) return
    disableGravity([body.current, mouseBody.current])
  }

  const setInitialPosition = () => {
    const { x, y, rx, ry } = getArrangementPosition()
    position.current.x = window.innerWidth / 2
    position.current.y = window.innerHeight / 2
    Matter.Body.setPosition(body.current, { x: x + rx, y: y + ry })
    Matter.Body.setPosition(mouseBody.current, { x: x + rx, y: y + ry })
  }

  useRafLoop((time) => {
    if (!ref.current) return
    resize()
    render(time)
    updateContent()
    updateOutline()
  })

  useEffect(() => {
    if (state.mode === "playground") {
      if (DEBUG) console.log("SET INITIAL POSITION", index)
      setInitialPosition()
    }
  }, [state.mode])

  useEffect(() => {
    if (visibleTimer.current) {
      clearTimeout(visibleTimer.current)
    }

    if (isSunset) {
      body.current.collisionFilter.group = -1
      mouseBody.current.collisionFilter.group = -1
      life.current = 0
      // @ts-ignore
      visibleTimer.current = setTimeout(() => {
        setVisible(false)
      }, 500)
    } else {
      body.current.collisionFilter.group = 0
      mouseBody.current.collisionFilter.group = 0
      life.current = 1
      setVisible(true)
    }
  }, [isSunset])

  useEffect(() => {
    if (visible) {
      // add the body to the world
      Matter.World.add(TilesState.engine.world, [mouseBody.current, body.current])
      TilesState.tileBodies.push(mouseBody.current)
      setInitialPosition()
    } else {
      // remove from tileBodies
      const i = TilesState.tileBodies.indexOf(mouseBody.current!)
      if (i > -1) {
        TilesState.tileBodies.splice(i, 1)
      }

      // remove the body from the world
      Matter.World.remove(TilesState.engine.world, [mouseBody.current!, body.current!], true)
    }
  }, [visible])

  if (env.client) {
    useLayoutEffect(() => {
      if (DEBUG) console.log("MOUNT", index)

      Matter.Events.on(TilesState.engine, "beforeUpdate", disableTileGravity)

      return () => {
        if (DEBUG) console.log("UNMOUNT", index)

        Matter.Events.off(TilesState.engine, "beforeUpdate", disableTileGravity)

        // remove from tileBodies
        const i = TilesState.tileBodies.indexOf(mouseBody.current!)
        if (i > -1) {
          TilesState.tileBodies.splice(i, 1)
        }

        // remove the body from the world
        Matter.World.remove(TilesState.engine.world, [mouseBody.current!, body.current!], true)
      }
    }, [])
  }

  return (
    <div ref={ref} className="absolute left-0 top-0 w-full h-full pointer-events-none">
      <ResizeCorner tile={outerRef} visible={visible && selected} corner="tl" ref={dotTLRef} />
      <ResizeCorner tile={outerRef} visible={visible && selected} corner="tr" ref={dotTRRef} />
      <ResizeCorner tile={outerRef} visible={visible && selected} corner="br" ref={dotBRRef} />
      <ResizeCorner tile={outerRef} visible={visible && selected} corner="bl" ref={dotBLRef} />
      <div ref={outlineRef} className="absolute left-0 top-0 origin-center pointer-events-none">
        {visible && selected && <TileOutline />}
      </div>
      <div ref={contentRef} className="relative origin-center">
        {visible && <div className="w-full h-full cursor-grab active:cursor-grabbing relative">{children}</div>}
      </div>
    </div>
  )
})
