import { Button } from "@components/controls/Button"
import { TilesState } from "../TilesState"

export const BecomeAMember = () => {
  return (
    <div className="flex w-full h-full justify-center items-center">
      <Button onClick={(e) => {
        TilesState.router?.handleClickEvent(e, '/join')
      }} className="w-fit pointer-events-auto">Become an AGDA member</Button>
    </div>
  )
}

BecomeAMember.widgetConfig = {
  id: "become-a-member",
  width: 220,
  height: 100,
  isHTML: true,
  alwaysOnTop: true,
  persist: true
}