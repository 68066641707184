import { useRef, useState } from "react"
import { BaseHTML } from "./BaseHTML"
import { Button } from "@components/controls/Button"
import { twMerge } from "tailwind-merge"

const TEXTS = [
  "I am a vector warrior in a raster world.",
  "I am scaling proportionally every day.",
  "I am at peace with my file naming conventions.",
  "I deserve happiness (a 12 month Adobe subscription).",
  "I choose to not know where my scratch disks are and why they are full.",
  "I forgive myself for not converting to smart object.",
  "I am unique and special, existing at the intersection of art and design.",
  "I am in harmony with the flow of life and Figma prototypes.",
  "I have the power to make the design ‘pop’.",
  "I release the need for approval from others (not including clients, creative directors and colleagues)",
  "I am aligned—vertically, horizontally, and spiritually.",
  "I give myself permission to outline my fonts.",
]

export const Affirmations = () => {
  const [index, setIndex] = useState(0)
  const order = useRef<number[]>(
    (() => {
      const arr = [...Array(TEXTS.length)].map((_, i) => i)
      arr.sort(() => Math.random() - 0.5)
      return arr
    })(),
  )

  const text = TEXTS[order.current[index]]

  return (
    <BaseHTML title="Affirmations">
      <div className="w-full h-full flex justify-between flex-col">
        <p className={twMerge("type-title-s-medium", text.length > 50 ? "type-title-xs-medium" : "")}>{text}</p>
        <Button className="w-fit pointer-events-auto" onClick={() => setIndex((index + 1) % TEXTS.length)}>
          Generate
        </Button>
      </div>
    </BaseHTML>
  )
}

Affirmations.widgetConfig = {
  id: "affirmations",
  width: 181,
  height: 226,
  isHTML: true,
  alwaysOnTop: false,
  persist: false
}
