// show debug stuff with collision boxes, walls, etc
export const DEBUG = false
// total number of items on the screen at a time
export const MAX_SLOTS = 5
export const MAX_SLOTS_MOBILE = 2
// total number of spaces to spawn items (give overlap so we can see items transition in and out)
export const MAX_SPACES = 15
// body size scale modifier for collisions. 1 = same size as mesh
export const TILE_OVERLAP_BOUNDARY_SCALE = 0.5
export const TILE_OVERLAP_BOUNDARY_SCALE_MOBILE = 0.9
// padding around the tile for the outline
export const OUTLINE_PADDING = 10
// lerp strength for tile positions and scales
export const LERP_STRENGTH = {
  SCALE: 0.15,
  POSITION: 0.085,
}
// max sizing of each image-based tile
export const MAX_TILE_WIDTH = 300
export const MAX_TILE_HEIGHT = 300
// max sizing of each image-based tile on mobile
export const MAX_TILE_WIDTH_MOBILE = 200
export const MAX_TILE_HEIGHT_MOBILE = 200
// thickness of the walls
export const WALL_PADDING = {
  top: 110,
  bottom: 100,
  left: 300,
  right: 100,
}
// https://brm.io/matter-js/docs/classes/Body.html#property_collisionFilter
export const COLLISION_FILTERS = {
  MOUSE: 0b001,
  INTERACT: 0b010,
  GENERAL: 0b100,
  PUNCHING: 0b1000,
  FIST: 0b1001,
  BALL: 0b10000,
  BRICK: 0b100000,
  WALL: 0b1000000,
}

export const GET_REM_SCALE = () => {
  let designWidth = 1280
  let designHeight = 768

  if (window.innerWidth <= 600) {
    designWidth = 375
    designHeight = 550
  }

  return Math.min(Math.min(designWidth, window.innerWidth) / designWidth, Math.min(designHeight, window.innerHeight) / designHeight)
}

export const ARRANGEMENT = [
  [1, 0, 1],
  [0, 1, 0],
  [1, 0, 0],
  [0, 1, 0],
  [0, 0, 1],
  [0, 1, 0],
]

export const ARRANGEMENT_MOBILE = [
  [1, 0, 0],
  [0, 1, 0],
  [0, 0, 1],
  [0, 1, 0],
  [1, 0, 0],
  [0, 0, 1],
]

export const findNextPosition = (index: number, arrangement = ARRANGEMENT) => {
  let ix = 0
  let col = 0
  let row = 0
  while (!(ix === index && arrangement[row][col] === 1)) {
    if (arrangement[row][col] === 1) {
      if (ix === index) {
        break
      }
      ix++
    }
    if (col === arrangement[row].length - 1) {
      col = 0
      row = row < arrangement.length - 1 ? row + 1 : 0
    } else {
      col++
    }
  }
  return { col, row }
}
