import { twMerge } from "tailwind-merge"
import { OUTLINE_PADDING } from "./config"

const TileDot = ({ className }: { className?: string }) => {
  return (
    <div className={twMerge("absolute w-5 h-5 cursor-move transform flex items-center justify-center", className)}>
      <div className="bg-black w-2 h-2" />
    </div>
  )
}

export const TileOutline = () => {
  return (
    <div className="absolute top-0 left-0 w-full h-full border border-black">
      <TileDot className="top-0 left-0 -translate-x-1/2 -translate-y-1/2 cursor-nwse-resize" />
      <TileDot className="top-0 right-0 translate-x-1/2 -translate-y-1/2 cursor-nesw-resize" />
      <TileDot className="bottom-0 left-0 -translate-x-1/2 translate-y-1/2 cursor-nesw-resize" />
      <TileDot className="bottom-0 right-0 translate-x-1/2 translate-y-1/2 cursor-nwse-resize" />
    </div>
  )
}