import { Link } from "eddev/routing"
import { EventCardFragment } from "../../types.graphql"
import { ResponsiveImage } from "@components/atoms/ResponsiveImage"
import { useMemo } from "react"
import { getDateRange } from "@utils/dates"
import { motion } from "framer-motion"
import { RouteFlipped } from "@components/motion/RouteFlipped"

type Props = EventCardFragment & {
  mode: "stacked" | "row"
  hovering?: boolean
  onHover?: (hovering: boolean) => void
}

export function EventMicroCard(props: Props) {
  const range = useMemo(() => {
    return getDateRange(props.dates.flatMap((d) => [d.startDate, d.endDate]).filter((d): d is string => !!d))
  }, [props.dates])

  if (props.mode === "stacked") {
    return (
      <Link href={props.uri} className="flex flex-col gap-1 w-full" onMouseEnter={() => props.onHover?.(true)} onMouseLeave={() => props.onHover?.(false)}>
        <motion.div variants={{ active: { width: "100%" }, default: { width: "65%" } }} animate={props.hovering ? "active" : "default"} initial="default">
          <RouteFlipped flipId={props.id!}>
            <ResponsiveImage {...props.hero?.image} />
          </RouteFlipped>
        </motion.div>
        <motion.div
          className="pointer-events-none"
          variants={{ active: { width: "150%" }, default: { width: "65%" } }}
          animate={props.hovering ? "active" : "default"}
          initial="default"
        >
          <div className="stack-y-0">
            <div className="type-body-s-roman text-ellipsis whitespace-nowrap overflow-hidden antialiased">{props.title}</div>
            <div className="type-body-s-serif text-ellipsis whitespace-nowrap overflow-hidden">{range}</div>
          </div>
        </motion.div>
      </Link>
    )
  } else {
    return (
      <Link
        href={props.uri}
        className="flex flex-row gap-4 w-full bg-grey-light items-center pr-4 overflow-hidden rounded-[3px] active:bg-grey-mid/30"
        onMouseEnter={() => props.onHover?.(true)}
        onMouseLeave={() => props.onHover?.(false)}
      >
        <div className="flex-none">
          <ResponsiveImage {...props.hero?.image} className="h-[40px]" />
        </div>
        <div className="flex-1 stack-y-0 overflow-hidden">
          <div className="type-body-s-roman text-ellipsis whitespace-nowrap overflow-hidden antialiased">{props.title}</div>
          <div className="type-body-s-serif text-ellipsis whitespace-nowrap overflow-hidden">{range}</div>
        </div>
      </Link>
    )
  }
}
