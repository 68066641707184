import Matter from "matter-js"
import { proxy, ref } from "valtio"
import { TileInfoType } from "./Tiles"
import { DraggingType } from "./Mouse"
import { AwardEntryCardFragment, EventCardFragment } from "../../../types.graphql"
import { RouterAPI } from "eddev/routing"
import { ARRANGEMENT, MAX_SLOTS, MAX_TILE_HEIGHT, MAX_TILE_WIDTH, TILE_OVERLAP_BOUNDARY_SCALE } from "./config"

type TilesMode = "playground" | "scroller"
type ContentFitType = {
  current: HTMLDivElement | null
}

export const TilesState = proxy({
  active: true,
  mode: "playground" as TilesMode,
  contentFitRef: ref<ContentFitType>({ current: null }),
  spawning: false,
  spawnCount: 0,
  persistentCount: 0,
  selected: -1,
  maxShown: MAX_SLOTS,
  canResize: true,
  canSelect: true,
  canUseWidgets: true,
  maxTileSize: {
    width: MAX_TILE_WIDTH,
    height: MAX_TILE_HEIGHT,
  },
  arrangement: ARRANGEMENT,
  overlapBoundaryScale: TILE_OVERLAP_BOUNDARY_SCALE,
  tileBodies: ref([] as Matter.Body[]),
  tileRefs: ref([] as TileInfoType[]),
  engine: ref(Matter.Engine.create()),
  items: [] as AwardEntryCardFragment[],
  events: [] as EventCardFragment[],
  router: null as null | RouterAPI,
  safezone: {
    left: 0,
    right: 0,
    bottom: 0,
    top: 0,
    get width() {
      return this.right - this.left
    },
    get height() {
      return this.bottom - this.top
    },
  },
  dragging: ref<DraggingType>({
    body: [],
    bodyTimer: null,
    corner: null,
    maybeClicking: false,
    down: false,
    downDrag: false,
    startPos: { x: 0, y: 0 },
    currentPos: { x: 0, y: 0 },
    previousPos: { x: 0, y: 0 },
    endPos: { x: 0, y: 0 },
    selecting: false,
    selectionComposite: Matter.Composite.create(),
    selectionCompositeCornerBodies: [],
  }),
})
