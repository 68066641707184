import Matter from "matter-js"
import { useRef, useEffect, PointerEvent } from "react"
import { useSnapshot } from "valtio"
import { TilesState } from "./TilesState"
import { COLLISION_FILTERS } from "./config"
import { getCompositionOutlinePositions } from "./utils"
import { ResizeCorner, ResizeCornerType } from "./ResizeCorner"
import { TileOutline } from "./TileOutline"
import { useRafLoop } from "react-use"

// a note about mouse interaction:
// because we are using Matter.js's mouse constraint for dragging items, which listens to the window,
// Matter needs to handle all mouse interaction (therefore r3f's onPointerDown/etc stuff won't work).

export type DraggingType = {
  // the current body being interacted with
  body: Matter.Body[]
  // for debouncing currently set body
  bodyTimer: number | null
  // the current resize corner being interacted with
  // (resize corners are also bodies, but with .isSensor=true to be excluded from collisions)
  corner: Matter.Body | null
  // mouse is down
  down: boolean
  // user might be clicking on a tile, rather than trying to drag/select it
  maybeClicking: boolean
  // mouse is down and dragging immediately
  downDrag: boolean
  // mouse positions during interaction
  startPos: { x: number; y: number }
  currentPos: { x: number; y: number }
  previousPos: { x: number; y: number }
  endPos: { x: number; y: number }
  selecting: boolean
  selectionComposite: Matter.Composite
  selectionCompositeCornerBodies: Matter.Body[]
}

export const Mouse = () => {
  const tilesState = useSnapshot(TilesState)
  const mouse = useRef<Matter.Mouse | null>(null)
  const mouseConstraint = useRef<Matter.MouseConstraint | null>(null)
  const dotTLRef = useRef<ResizeCornerType>(null)
  const dotTRRef = useRef<ResizeCornerType>(null)
  const dotBRRef = useRef<ResizeCornerType>(null)
  const dotBLRef = useRef<ResizeCornerType>(null)
  const htmlSelectorLineRef = useRef<HTMLDivElement>(null)
  const htmlSelectionLineRef = useRef<HTMLDivElement>(null)
  const cornersRef = useRef<HTMLDivElement>(null)

  const drawSelectorLine = () => {
    if (htmlSelectorLineRef.current) {
      const start = { x: TilesState.dragging.startPos.x, y: TilesState.dragging.startPos.y }
      const current = { x: TilesState.dragging.currentPos.x, y: TilesState.dragging.currentPos.y }
      htmlSelectorLineRef.current.style.transform = `translate(${Math.min(start.x, current.x)}px, ${Math.min(start.y, current.y)
        }px)`
      htmlSelectorLineRef.current.style.width = `${Math.abs(start.x - current.x)}px`
      htmlSelectorLineRef.current.style.height = `${Math.abs(start.y - current.y)}px`

      if (TilesState.dragging.selecting) {
        htmlSelectorLineRef.current.style.display = "block"
      } else {
        htmlSelectorLineRef.current.style.display = "none"
      }
    }
  }

  const drawSelectionLine = () => {
    if (TilesState.dragging.body.length > 1) {
      const { tlX, tlY, trX, trY, brX, brY, blX, blY } = getCompositionOutlinePositions(TilesState.dragging.selectionComposite)

      if (htmlSelectionLineRef.current) {
        htmlSelectionLineRef.current.style.transform = `translate(${tlX}px, ${tlY}px)`
        htmlSelectionLineRef.current.style.width = `${Math.abs(trX - tlX)}px`
        htmlSelectionLineRef.current.style.height = `${Math.abs(blY - tlY)}px`
        htmlSelectionLineRef.current.style.display = "block"
      }
      if (cornersRef.current) {
        cornersRef.current.style.display = "block"
      }
    } else {
      if (htmlSelectionLineRef.current) {
        htmlSelectionLineRef.current.style.display = "none"
      }
      if (cornersRef.current) {
        cornersRef.current.style.display = "none"
      }
    }
  }

  const startDrag = (e: any) => {
    if (!TilesState.canSelect) return

    const body = (e as any).body as Matter.Body
    const mouse = (e as any).mouse as Matter.Mouse
    const atPoint = Matter.Query.point(TilesState.tileBodies, mouse.position)

    if (TilesState.dragging.selecting) {
      // don't select a new body if we're in the middle of selecting multiple
      if (TilesState.dragging.bodyTimer) {
        clearTimeout(TilesState.dragging.bodyTimer)
      }
      TilesState.dragging.corner = null

      // prevent mouse constraint from dragging the body
      mouseConstraint.current!.constraint.bodyB = null

      return
    } else {
      // console.log('select none')
      // TilesState.dragging.body = []
    }

    // is there a selected body already
    // and is it on top of other bodies we might try to drag?
    let preventNewSelection = false
    if (TilesState.dragging.body.length > 0) {
      TilesState.dragging.body.forEach((b) => {
        if (atPoint.includes(b)) {
          preventNewSelection = true
        }
      })
    }

    // if multiple are selected, are we dragging the composite instead?
    if (TilesState.dragging.body.length > 1 && !preventNewSelection) {
      if (!body.isSensor && !TilesState.dragging.corner) {
        TilesState.dragging.corner = null
        mouseConstraint.current!.constraint.bodyB = null
        return
      } else if (body.isSensor && !TilesState.dragging.corner && TilesState.dragging.selectionCompositeCornerBodies.indexOf(body) > -1) {
        // dragging a corner of a composite
        TilesState.dragging.corner = body
        return
      }
    }

    if (!body.isSensor) {
      // dragging a tile
      if (!TilesState.dragging.corner) {
        if (TilesState.dragging.bodyTimer) {
          clearTimeout(TilesState.dragging.bodyTimer)
        }
        // delay slightly to make sure we're not already dragging a resize corner
        // @ts-ignore
        TilesState.dragging.bodyTimer = setTimeout(() => {
          if (!preventNewSelection) {
            // find the body atPoint with the lowest render order
            if (TilesState.tileRefs.length === 0) return
            const sorted = atPoint.sort((a, b) => {
              const tileA = TilesState.tileRefs.find((t) => t?.ref?.getBody() === a)
              const tileB = TilesState.tileRefs.find((t) => t?.ref?.getBody() === b)
              return (tileA?.renderOrder || 0) - (tileB?.renderOrder || 0)
            })
            TilesState.dragging.body = [sorted[0]]
          } else if (TilesState.dragging.body.length > 0) {
            // make sure we're always dragging the original body then
            mouseConstraint.current!.constraint.bodyB = TilesState.dragging.body[0]
            mouseConstraint.current!.constraint.pointB = {
              x: mouse.position.x - TilesState.dragging.body[0].position.x,
              y: mouse.position.y - TilesState.dragging.body[0].position.y,
            }
          }
        }, 50)
      }
    } else if (TilesState.dragging.body.length <= 1) {
      if (!TilesState.canResize) return
      // dragging a resize corner (probably)
      TilesState.dragging.corner = body
    }
  }

  const mouseDown = (e: PointerEvent) => {
    const el = e.currentTarget as HTMLElement
    TilesState.dragging.down = true
    TilesState.dragging.startPos = { x: mouse.current!.position.x, y: mouse.current!.position.y }
    TilesState.dragging.currentPos = { x: mouse.current!.position.x, y: mouse.current!.position.y }
    TilesState.dragging.selecting = false
    TilesState.dragging.downDrag = false
    TilesState.dragging.maybeClicking = !e.shiftKey

    // is there a body to drag where we started?
    const atPoint = Matter.Query.point(TilesState.tileBodies, mouse.current!.position)
    if (atPoint.length > 0) {
      TilesState.dragging.downDrag = true
    }
    if (e.shiftKey && atPoint.length > 0 && TilesState.canSelect) {
      TilesState.dragging.body.push(...atPoint)
      Matter.Composite.clear(TilesState.dragging.selectionComposite, true)
      Matter.Composite.add(TilesState.dragging.selectionComposite, TilesState.dragging.body)
    }
  }

  const mouseMove = (e: PointerEvent) => {
    const el = e.currentTarget as HTMLElement
    TilesState.dragging.currentPos = { x: mouse.current!.position.x, y: mouse.current!.position.y }
    const dist = Math.sqrt((TilesState.dragging.currentPos.x - TilesState.dragging.startPos.x) ** 2 + (TilesState.dragging.currentPos.y - TilesState.dragging.startPos.y) ** 2)

    if (dist > 5 && TilesState.dragging.down) {
      TilesState.dragging.maybeClicking = false
      el.setPointerCapture(e.pointerId)
    }

    if (
      TilesState.dragging.down &&
      !TilesState.dragging.downDrag &&
      !TilesState.dragging.corner &&
      (Math.abs(TilesState.dragging.currentPos.x - TilesState.dragging.startPos.x) > 1 || Math.abs(TilesState.dragging.currentPos.y - TilesState.dragging.startPos.y) > 1) &&
      TilesState.canSelect
    ) {
      TilesState.dragging.selecting = true
    }

    if (TilesState.dragging.selecting && TilesState.canSelect) {
      // check region for selecting multiple tiles
      const inRegion = Matter.Query.region(TilesState.tileBodies, {
        min: {
          x: Math.min(TilesState.dragging.startPos.x, TilesState.dragging.currentPos.x),
          y: Math.min(TilesState.dragging.startPos.y, TilesState.dragging.currentPos.y),
        },
        max: {
          x: Math.max(TilesState.dragging.startPos.x, TilesState.dragging.currentPos.x),
          y: Math.max(TilesState.dragging.startPos.y, TilesState.dragging.currentPos.y),
        },
      })

      // NOTE:
      // it seems like sometimes old bodies are still detected in the region?
      // filtering them out here, but that's kind of concerning...
      const ids = [...new Set(inRegion.map((b) => b.id))]
      const bodies = ids.map((id) => inRegion.find((b) => b.id === id)!)
      TilesState.dragging.body = bodies

      Matter.Composite.clear(TilesState.dragging.selectionComposite, true)
      Matter.Composite.add(TilesState.dragging.selectionComposite, bodies)
    }

    if (TilesState.dragging.down && !TilesState.dragging.selecting && TilesState.dragging.body.length > 1 && !TilesState.dragging.corner && TilesState.canSelect) {
      // dragging a composite
      Matter.Composite.translate(TilesState.dragging.selectionComposite, {
        x: TilesState.dragging.currentPos.x - TilesState.dragging.previousPos.x,
        y: TilesState.dragging.currentPos.y - TilesState.dragging.previousPos.y,
      })
    }

    TilesState.dragging.previousPos = { x: TilesState.dragging.currentPos.x, y: TilesState.dragging.currentPos.y }
  }

  const mouseUp = (e: PointerEvent) => {
    e.currentTarget.releasePointerCapture(e.pointerId)
    TilesState.dragging.down = false
    TilesState.dragging.endPos = { x: mouse.current!.position.x, y: mouse.current!.position.y }
    TilesState.dragging.corner = null
    TilesState.dragging.selecting = false
    TilesState.dragging.downDrag = false

    let atPoint = Matter.Query.point(TilesState.tileBodies, mouse.current!.position)

    if (TilesState.dragging.maybeClicking) {
      atPoint = []
      TilesState.dragging.body = []
    }

    if (Math.abs(TilesState.dragging.currentPos.x - TilesState.dragging.startPos.x) < 5 && Math.abs(TilesState.dragging.currentPos.y - TilesState.dragging.startPos.y) < 5) {
      // if one of the current bodies is at the point, keep it selected
      let included = false
      TilesState.dragging.body.forEach((b) => {
        if (atPoint.includes(b)) {
          included = true
        }
      })
      if (!included) {
        // not included, so we're fine to select whatever is at the point
        // however, let's select the tile with the lowest render order
        const sorted = atPoint.sort((a, b) => {
          const tileA = TilesState.tileRefs.find((t) => t.ref?.getBody() === a)
          const tileB = TilesState.tileRefs.find((t) => t.ref?.getBody() === b)
          return tileA!.renderOrder - tileB!.renderOrder
        })
        TilesState.dragging.body = sorted.length > 0 ? [sorted[0]] : atPoint[0] ? [atPoint[0]] : []
        if (TilesState.dragging.body.length > 0) {
          const tile = TilesState.tileRefs.find((t) => t.ref?.getBody() === TilesState.dragging.body[0])
          tile?.ref?.setAsSelected(true)
        }
      }
    }

    setTimeout(() => {
      TilesState.dragging.maybeClicking = false
    })
  }

  const endDrag = (e: any) => {
    const body = (e as any).body as Matter.Body
    const mouse = (e as any).mouse as Matter.Mouse

    TilesState.dragging.down = false
    TilesState.dragging.endPos = { x: mouse.position.x, y: mouse.position.y }
  }

  useEffect(() => {
    const eventTarget = document.getElementById("background") || window.document.body
    mouse.current = Matter.Mouse.create(eventTarget)
    // the mouse constraint is how we interact with bodies easily in Matter.js
    // dragging, throwing around, etc
    mouseConstraint.current = Matter.MouseConstraint.create(TilesState.engine, {
      mouse: mouse.current,
      constraint: {
        stiffness: 0.9,
        render: {
          visible: false,
        },
      },
      collisionFilter: {
        category: COLLISION_FILTERS.MOUSE,
        mask: COLLISION_FILTERS.INTERACT,
      },
    })

    Matter.Events.on(mouseConstraint.current, "startdrag", startDrag)
    // @ts-ignore
    eventTarget.addEventListener("pointerdown", mouseDown)
    // @ts-ignore
    eventTarget.addEventListener("pointermove", mouseMove)
    // @ts-ignore
    eventTarget.addEventListener("pointerup", mouseUp)
    // @ts-ignore
    eventTarget.addEventListener("pointercancel", mouseUp)
    Matter.Events.on(mouseConstraint.current, "enddrag", endDrag)
    Matter.Composite.add(TilesState.engine.world, mouseConstraint.current)

    // @ts-ignore
    mouse.current.element.removeEventListener('wheel', mouse.current.mousewheel)
    // @ts-ignore
    mouse.current.element.removeEventListener('DOMMouseScroll', mouse.current.mousewheel)

    return () => {
      if (mouse.current && mouseConstraint.current) {
        Matter.Events.off(mouseConstraint.current, "startdrag", startDrag)
        Matter.Events.off(mouseConstraint.current, "enddrag", endDrag)
        Matter.Composite.remove(TilesState.engine.world, mouseConstraint.current!)
        mouse.current = null
        mouseConstraint.current = null
      }
      // @ts-ignore
      eventTarget.removeEventListener("pointerdown", mouseDown)
      // @ts-ignore
      eventTarget.removeEventListener("pointermove", mouseMove)
      // @ts-ignore
      eventTarget.removeEventListener("pointerup", mouseUp)
      // @ts-ignore
      eventTarget.removeEventListener("pointercancel", mouseUp)
    }
  }, [])

  useEffect(() => {
    const types = [
      { event: 'mousemove', handler: 'mousemove' },
      { event: 'mousedown', handler: 'mousedown' },
      { event: 'mouseup', handler: 'mouseup' },
      { event: 'touchmove', handler: 'mousemove' },
      { event: 'touchstart', handler: 'mousedown' },
      { event: 'touchend', handler: 'mouseup' },
    ]

    // always start by removing
    types.forEach(({ event, handler }) => {
      // @ts-ignore
      mouse.current.element.removeEventListener(event, mouse.current[handler])
    })

    if (TilesState.canSelect) {
      types.forEach(({ event, handler }) => {
        // @ts-ignore
        mouse.current.element.addEventListener(event, mouse.current[handler])
      })
    }
  }, [tilesState.canSelect])

  useRafLoop(() => {
    drawSelectorLine()
    drawSelectionLine()
  })

  return (
    <div className="absolute left-0 top-0 w-full h-full pointer-events-none">
      <div ref={cornersRef} className="absolute left-0 top-0 w-full h-full pointer-events-none">
        <ResizeCorner corner="tl" ref={dotTLRef} composite visible />
        <ResizeCorner corner="tr" ref={dotTRRef} composite visible />
        <ResizeCorner corner="br" ref={dotBRRef} composite visible />
        <ResizeCorner corner="bl" ref={dotBLRef} composite visible />
      </div>
      <div ref={htmlSelectorLineRef} className="absolute top-0 left-0 border border-dashed border-[#999999] hidden" />
      <div ref={htmlSelectionLineRef} className="absolute top-0 left-0 hidden cursor-grab active:cursor-grabbing">
        <TileOutline />
      </div>
    </div>
  )
}
