import { useRef, useState } from "react"
import { BaseHTML } from "./BaseHTML"
import { Button } from "@components/controls/Button"

const TEXTS = [
  "Until Our Curves Meet and Pens Are Found",
  "May Your Entanglement Be Minimal—Like My Effort",
  "Wishing Optimal Convergence and No Zoom Issues",
  "Farewell Until Metrics Align and Coffee Works",
  "May Your Parameters Stay Consistent—Like My Snacks",
  "Parting in Eigenvectors and Avoiding Awkwardness",
  "Until Our Domains Overlap and Mug Mystery Solved",
  "May Your Gradients Be Smooth and WiFi Strong",
  "Farewell Until Tensors Match and Temp Is Fixed",
  "Wishing Precision and a Great Lunch Break",
  "Until Our Axes Align and We Find the Remote",
  "May Your Vectors Be Straight and Coffee Hot",
  "Wishing Smooth Transitions and No Email Glitches",
  "Farewell Until Coordinates Match and Printer Works",
  "May Your Entropy Be Low and WiFi Fast",
  "Parting in Parallel Lines and No Zoom Fatigue",
  "Until Our Phases Sync and Snacks Are Restocked",
  "May Your Data Be Clean and Meetings Short",
  "Farewell Until Systems Align and AC Cools",
  "Wishing Consistent Results and Endless Coffee",
  "Until Functions Integrate and WiFi Stays Strong",
  "May Your Variables Be Stable and Days Short",
  "Parting in Harmony and Delicious Lunches",
  "Until Equations Balance and Chair Reclines",
  "Wishing Optimal Parameters and Perfect Timing",
  "Farewell Until Curves Align and Mystery Solved",
  "May Your Insights Be Clear and Deadlines Flexible",
  "Parting in Quantum States and Finding Pens",
  "Until Systems Sync and Snacks Are Plenty",
  "Wishing Accurate Predictions and No Spills",
  "Farewell Until Vectors Align and Coffee’s Hot",
  "May Your Margins Be Generous and Days Smooth",
  "Parting in Precision and Printer Doesn’t Jam"
]

export const EmailSignoff = () => {
  const [index, setIndex] = useState(0)
  const order = useRef<number[]>((() => {
    const arr = [...Array(TEXTS.length)].map((_, i) => i)
    arr.sort(() => Math.random() - 0.5)
    return arr
  })())

  return (
    <BaseHTML title="Email Sign-Off">
      <div className="w-full h-full flex justify-between flex-col">
        <p className="type-title-m-serif">
          {TEXTS[order.current[index]]}
        </p>
        <Button className="w-fit pointer-events-auto" onClick={() => setIndex((index + 1) % TEXTS.length)}>Generate</Button>
      </div>
    </BaseHTML>
  )
}

EmailSignoff.widgetConfig = {
  id: "email-signoff",
  width: 287,
  height: 198,
  isHTML: true,
  alwaysOnTop: false,
  persist: false
}