import React from "react"
import { twMerge } from "tailwind-merge"

export const BaseHTML = ({ title, children }: { title?: string; children?: React.ReactNode; }) => {
  return (
    <div className="w-full h-full border-t border-solid border-black bg-grey-light px-4 py-5">
      {title && <h2 className="type-ui-s-roman-caps text-grey mb-4">{title}</h2>}
      <div className={twMerge("w-full relative", title ? "h-[calc(100%-var(--spacing-5))]" : "h-full")}>
        {children}
      </div>
    </div>
  )
}