import { Button } from "@components/controls/Button"
import { IconInspire } from "@components/svg/Icons"
import Matter from "matter-js"
import { wrap } from "popmotion"
import { Suspense, useEffect, useRef } from "react"
import { useRafLoop, useWindowSize } from "react-use"
import { useSnapshot } from "valtio"
import VirtualScroll, { VirtualScrollEvent } from "virtual-scroll"
import { AwardEntryCardFragment } from "../../../types.graphql"
import { MatterDebugger } from "./MatterDebugger"
import { Mouse } from "./Mouse"
import { Tile, TileType } from "./Tile"
import { TilesState } from "./TilesState"
import { Walls } from "./Walls"
import { ARRANGEMENT, ARRANGEMENT_MOBILE, DEBUG, GET_REM_SCALE, LERP_STRENGTH, MAX_SLOTS, MAX_SLOTS_MOBILE, MAX_SPACES, MAX_TILE_HEIGHT, MAX_TILE_HEIGHT_MOBILE, MAX_TILE_WIDTH, MAX_TILE_WIDTH_MOBILE, TILE_OVERLAP_BOUNDARY_SCALE, TILE_OVERLAP_BOUNDARY_SCALE_MOBILE } from "./config"
import { clamp, map } from "./utils"
import { Affirmations } from "./widgets/Affirmations"
import { EmailBreaker } from "./widgets/EmailBreaker"
import { EmailSignoff } from "./widgets/EmailSignOff"
import { Image } from "./widgets/Image"
import { PunchingBag } from "./widgets/PunchingBag"
import { Finalists } from "./widgets/Finalists"
import { BecomeAMember } from "./widgets/BecomeAMember"

export type TileInfoType = {
  url?: string
  index: number
  lastInstance?: number
  slot: number
  ref: TileType | null
  renderOrder: number
  content?: React.ReactNode
  width?: number
  height?: number
  isHTML?: boolean
  alwaysOnTop?: boolean
  persist?: boolean
  id?: string
}

type TileScrollPositionType = {
  x: number
  y: number
  rx: number
  ry: number
  naturalY: number
  width: number
  height: number
  wigglex: number
  wiggley: number
}

const WIDGETS = [EmailBreaker, Affirmations, PunchingBag, EmailSignoff]
const PERSISTENT_WIDGETS = [Finalists, BecomeAMember]

export function Tiles() {
  const { width, height } = useWindowSize()
  // only so many items can be shown at a time.
  // make sure the max is a little bit more than shown so you can nicely transition between shown items quickly.
  // recycle slots for new items once their lifespan has ended.
  const slots = [...Array(MAX_SPACES)].map(() => useRef<TileType>(null))
  const persistentSlots = [...Array(PERSISTENT_WIDGETS.length)].map(() => useRef<TileType>(null))
  const tileOrders = useRef<number[]>([])
  const persistTileOrders = useRef<number[]>([])
  const tiles = useRef<TileInfoType[]>([])
  const persistentTiles = useRef<TileInfoType[]>([])
  const state = useSnapshot(TilesState)
  const widgetRotation = useRef(0)
  const scroller = useRef<VirtualScroll | null>(null)
  const scroll = useRef<VirtualScrollEvent | null>(null)
  const scrollPositions = useRef<TileScrollPositionType[]>([])
  const fpsTimer = useRef({ elapsed: 0, then: 0, fps: 60 })

  // because of suspense, we can't update *all* the tile orders
  // since some might still be loading, so when one loads, update the order again
  const SlotLoader = ({ slot }: { slot: number }) => {
    useEffect(() => {
      updateTileOrders()

      const t = tiles.current[slot]
      if (t?.ref && !t?.ref?.getIsSunset()) {
        // console.log('REFRESH', t.slot)
        t.ref?.refresh(t.width, t.height)
      }
    }, [])
    return null
  }

  const PersistentSlotLoader = ({ slot }: { slot: number }) => {
    useEffect(() => {
      updateTileOrders()

      const t = persistentTiles.current[slot]
      if (t?.ref && !t?.ref?.getIsSunset()) {
        // console.log('REFRESH', t.slot)
        t.ref?.refresh(t.width, t.height)
      }
    }, [])
    return null
  }

  TilesState.engine.gravity.y = 5
  TilesState.engine.positionIterations = 10
  TilesState.engine.velocityIterations = 10

  const spawn = (slot: number, item?: AwardEntryCardFragment) => {
    let t = tiles.current
    tileOrders.current.unshift(slot)

    let Widget =
      TilesState.canUseWidgets && (
        TilesState.spawnCount % TilesState.maxShown === TilesState.maxShown - 1 ||
        !item?.hero?.srcSet
      ) ?
        WIDGETS[widgetRotation.current++ % WIDGETS.length] :
        null

    t[slot] = Object.assign(
      {},
      {
        index: TilesState.spawnCount % MAX_SPACES,
        slot,
        renderOrder: 0,
        ref: t[slot]?.ref,
        content: item?.hero?.srcSet ? <Image src={item.hero?.srcSet} url={item.uri} tile={slots[slot]} flipId={item.id} /> : null,
        isHTML: Image.widgetConfig.isHTML,
        alwaysOnTop: Image.widgetConfig.alwaysOnTop,
        persist: Image.widgetConfig.persist,
        id: Image.widgetConfig.id,
      },
      Widget
        ? {
          content: <Widget />,
          width: Widget.widgetConfig.width,
          height: Widget.widgetConfig.height,
          isHTML: Widget.widgetConfig.isHTML,
          alwaysOnTop: Widget.widgetConfig.alwaysOnTop,
          persist: Widget.widgetConfig.persist,
          id: Widget.widgetConfig.id,
        }
        : {}
    )

    TilesState.spawnCount++
  }

  const spawnPersistent = (slot: number, Widget: any) => {
    let t = persistentTiles.current
    persistTileOrders.current.unshift(slot)

    t[slot] = Object.assign(
      {},
      {
        index: TilesState.persistentCount % MAX_SPACES,
        slot,
        renderOrder: 0,
        ref: t[slot]?.ref,
        content: <Widget />,
        width: Widget.widgetConfig.width,
        height: Widget.widgetConfig.height,
        isHTML: Widget.widgetConfig.isHTML,
        alwaysOnTop: Widget.widgetConfig.alwaysOnTop,
        persist: Widget.widgetConfig.persist,
        id: Widget.widgetConfig.id,
      }
    )

    TilesState.persistentCount++
  }

  const updateTiles = () => {
    [...tiles.current, ...persistentTiles.current].forEach((tile, i) => {
      TilesState.tileRefs[i] = tile
    })
  }

  const removeTile = (index: number) => {
    if (tiles.current[index] && slots[index].current) {
      const slot = tileOrders.current.indexOf(index)
      slots[index].current?.sunset(true)
      if (DEBUG) console.log('REMOVE TILE', index, slot)
      // setTimeout(() => {
      delete tiles.current[index]
      tileOrders.current.splice(slot, 1)
      // }, 500)
      TilesState.selected = -1
      // TilesState.spawnCount--

      return true
    }
    return false
  }

  const updateTileOrders = () => {
    let orders = [...new Set(tileOrders.current)]
    tileOrders.current = orders

    for (let x = 0; x < orders.length; x++) {
      // console.log('SLOT', orders[x], 'ORDER', x, orders)

      if (slots[orders[x]].current) {
        if (x < TilesState.maxShown) {
          // keep some widgets on top?
          const order = tiles.current[orders[x]].alwaysOnTop ? -1 : x

          tiles.current[orders[x]].renderOrder = order
          tiles.current[orders[x]].ref = slots[orders[x]].current
          slots[orders[x]].current?.setRenderOrder(order)
          slots[orders[x]].current?.sunset(false)
        } else {
          if (removeTile(orders[x])) return updateTileOrders()
        }
      }
    }

    let porders = [...new Set(persistTileOrders.current)]
    persistTileOrders.current = porders

    for (let x = 0; x < porders.length; x++) {
      if (persistentSlots[porders[x]].current) {
        // keep some widgets on top?
        const order = persistentTiles.current[porders[x]].alwaysOnTop ? -1 : x

        persistentTiles.current[porders[x]].renderOrder = order
        persistentTiles.current[porders[x]].ref = persistentSlots[porders[x]].current
        persistentSlots[porders[x]].current?.setRenderOrder(order)
        persistentSlots[porders[x]].current?.sunset(false)
      }
    }

    updateTiles()
  }

  const keyDown = (e: KeyboardEvent) => {
    // delete all selected tiles
    if ((e.key === "Delete" || e.key === "Backspace") && TilesState.dragging.body.length > 0) {
      // get refs of all selected bodies
      const selected = TilesState.dragging.body.map((b) => TilesState.tileRefs.find((t) => t?.ref?.getBody() === b))

      // remove all selected tiles
      selected.forEach((t) => {
        if (t && !t.persist) {
          removeTile(t.slot)
        }
      })

      TilesState.dragging.selecting = false
      TilesState.dragging.body = []
    }
  }

  const spawnNew = (slot?: number) => {
    if (state.items.length < 1) return

    spawn(typeof slot === "number" ? slot : TilesState.spawnCount % MAX_SPACES, state.items[TilesState.spawnCount % state.items.length])
  }

  const updateScroll = (e: VirtualScrollEvent) => {
    scroll.current = e
  }

  const renderScroller = (clock: number) => {
    if (state.mode === "scroller") {
      const { x, y } = scroll.current || { x: 0, y: 0 }
      const remScale = GET_REM_SCALE()
      const spaceBetween = 25 * remScale

      let areaWidth = width
      let areaMarginLeft = 0
      let lastRow = 0
      let lastHeight = 0
      let maxHeight = 0

      const fitAreaEl = TilesState.contentFitRef.current
      if (fitAreaEl) {
        const rect = fitAreaEl.getBoundingClientRect()
        areaWidth = rect.width
        areaMarginLeft = rect.left
      }

      tiles.current.forEach((tile, i) => {
        if (!scrollPositions.current[i]) {
          scrollPositions.current[i] = { x: 0, y: 0, rx: 0, ry: 0, naturalY: 0, width: 0, height: 0, wigglex: 0, wiggley: 0 }
        }

        const sp = scrollPositions.current[i]
        const prevSP = i < 1 ? null : scrollPositions.current[i - 1]

        if (tile.ref) {
          const pos = tile.ref?.getPosition()
          const arrangementPos = tile.ref?.getArrangementPosition()
          const arrangement = tile.ref?.getArrangement()
          const scale = tile.ref?.getScale()
          const size = { x: scale.x, y: MAX_TILE_HEIGHT * remScale }

          // if (arrangement.row !== lastRow) {
          //   lastRow = arrangement.row
          lastHeight += maxHeight + spaceBetween
          //   maxHeight = 0
          // }

          if (sp.rx === 0) {
            sp.x = pos.x
            sp.y = pos.y
            sp.rx = arrangementPos.rx
            sp.ry = arrangementPos.ry
          }

          let px = arrangementPos.x + sp.rx
          if (prevSP && px < prevSP.x + size.x && px > prevSP.x) {
            px += prevSP.width
          }
          px = clamp(px, areaMarginLeft + size.x / 1.5, areaMarginLeft + areaWidth - size.x / 2)
          const ypos = lastHeight + spaceBetween
          const py = y - ypos + height
          const parallax = map(py, -size.y, height + size.y, -1, 1, true)
          const wigglex = Math.sin((clock + 80 * i) * 0.2) * 0.1 * size.x
          const wiggley = Math.sin((clock + 100 * i) * 0.2) * 0.1 * size.y
          sp.x += (px - sp.x) * LERP_STRENGTH.POSITION
          sp.y += (py - sp.y) * LERP_STRENGTH.POSITION
          sp.naturalY = ypos
          sp.width = size.x
          sp.height = size.y
          sp.wigglex = wigglex
          sp.wiggley = wiggley
          maxHeight = Math.max(maxHeight, size.y)
        }
      })

      tiles.current.forEach((tile, i) => {
        if (tile.ref) {
          const pos = scrollPositions.current[i]
          const y = wrap(-pos.height * 2, height + pos.height * 2, pos.y + pos.wiggley)
          tile.ref?.setPosition(pos.x + pos.wigglex, y)

          const scrollInstance = Math.floor((pos.y + pos.height * 2) / (height + pos.height * 4))
          if (typeof tile.lastInstance !== "number") {
            tile.lastInstance = scrollInstance
          }
          if (tile.lastInstance !== scrollInstance) {
            spawnNew(tile.slot)
            tile.lastInstance = scrollInstance
          }
        }
      })
    }
  }

  useRafLoop((time) => {
    // limit fps
    fpsTimer.current.elapsed = time - fpsTimer.current.then
    const interval = 1000 / fpsTimer.current.fps
    if (fpsTimer.current.elapsed > interval) {
      Matter.Engine.update(TilesState.engine, interval)
      fpsTimer.current.then = time - (fpsTimer.current.elapsed % interval)
    }

    // renderScroller(clock)
  })

  useEffect(() => {
    if (width <= 700) {
      TilesState.maxTileSize.width = MAX_TILE_WIDTH_MOBILE
      TilesState.maxTileSize.height = MAX_TILE_HEIGHT_MOBILE
      TilesState.arrangement = ARRANGEMENT_MOBILE
      TilesState.maxShown = MAX_SLOTS_MOBILE
      TilesState.canResize = false
      TilesState.overlapBoundaryScale = TILE_OVERLAP_BOUNDARY_SCALE_MOBILE
      TilesState.canUseWidgets = false
      TilesState.canSelect = false
    } else {
      TilesState.maxTileSize.width = MAX_TILE_WIDTH
      TilesState.maxTileSize.height = MAX_TILE_HEIGHT
      TilesState.arrangement = ARRANGEMENT
      TilesState.maxShown = MAX_SLOTS
      TilesState.canResize = true
      TilesState.overlapBoundaryScale = TILE_OVERLAP_BOUNDARY_SCALE
      TilesState.canUseWidgets = true
      TilesState.canSelect = true
    }
  }, [width])

  useEffect(() => {
    updateTileOrders()
  }, [state.spawnCount])

  useEffect(() => {
    // @ts-ignore
    const isPersistent = persistentTiles.current.find(t => TilesState.dragging.body.includes(t?.ref?.getBody()))
    if (isPersistent) {
      if (DEBUG) console.log('do not update persistent selected')
      return
    }

    if (state.selected > -1 && TilesState.dragging.body.length < 2) {
      // fix selected render order
      tileOrders.current.unshift(state.selected)
      updateTileOrders()
    } else if (state.selected === -1) {
      updateTileOrders()
    }
  }, [state.selected])

  useEffect(() => {
    if (state.items.length < 1) return

    if (state.spawning) {
      spawnNew()
      TilesState.spawning = false
    }
  }, [state.spawning, state.items])

  useEffect(() => {
    scroller.current?.off(updateScroll)
    scroller.current?.destroy()
    if (state.mode === "scroller") {
      scrollPositions.current = []
      scroll.current = null
      scroller.current = new VirtualScroll()
      scroller.current?.on(updateScroll)
    }
  }, [state.mode])

  useEffect(() => {
    if (state.items.length < 1) return

    // reset TilesState
    widgetRotation.current = 0
    TilesState.spawnCount = 0
    TilesState.selected = -1

    const staggerDelay = 100

    for (let x = 0; x < TilesState.maxShown; x++) {
      setTimeout(() => {
        spawn(x, state.items[x])
      }, x * staggerDelay)
    }

    // manually add persistent items
    PERSISTENT_WIDGETS.forEach((widget, i) => {
      setTimeout(() => {
        spawnPersistent(i, widget)
      }, (TilesState.maxShown * staggerDelay) + (i * staggerDelay))
    })

    // listen for delete key
    document.addEventListener("keydown", keyDown)

    return () => {
      document.removeEventListener("keydown", keyDown)
      scroller.current?.off(updateScroll)
      scroller.current?.destroy()
    }
  }, [state.items, state.maxShown, state.canSelect, state.canUseWidgets])

  return (
    <div className="absolute left-0 top-0 w-full h-full">
      {DEBUG && (
        <MatterDebugger />
      )}
      {state.mode === "playground" && (
        <div className="absolute left-0 top-0 w-full h-full pointer-events-none flex items-start md:items-end z-[600]">
          <div className="h-[calc(100svh-80px)] md:h-pageMin w-grid relative mx-auto">
            <div className="absolute left-1/2 bottom-0 -translate-x-1/2 -translate-y-1/2 pointer-events-auto">
              <Button leftIcon={<IconInspire />} onClick={() => spawnNew()} variant="glass" size="lgSquare" />
            </div>
          </div>
        </div>
      )}
      <Walls />
      {persistentSlots.map((slot, i) =>
        state.persistentCount && typeof persistentTiles.current[i]?.slot === "number" ? (
          <Suspense key={i}>
            <PersistentSlotLoader slot={persistentTiles.current[i].slot} />
            <Tile
              ref={slot}
              url={persistentTiles.current[i].url}
              index={persistentTiles.current[i].index}
              slot={persistentTiles.current[i].slot}
              renderOrder={persistentTiles.current[i].renderOrder}
              width={persistentTiles.current[i].width}
              height={persistentTiles.current[i].height}
              isHTML={persistentTiles.current[i].isHTML}
            >
              {persistentTiles.current[i].content}
            </Tile>
          </Suspense>
        ) : null
      )}
      {slots.map((slot, i) =>
        state.spawnCount && typeof tiles.current[i]?.slot === "number" ? (
          <Suspense key={i}>
            <SlotLoader slot={tiles.current[i].slot} />
            <Tile
              ref={slot}
              url={tiles.current[i].url}
              index={tiles.current[i].index}
              slot={tiles.current[i].slot}
              renderOrder={tiles.current[i].renderOrder}
              width={tiles.current[i].width}
              height={tiles.current[i].height}
              isHTML={tiles.current[i].isHTML}
            >
              {tiles.current[i].content}
            </Tile>
          </Suspense>
        ) : null
      )}
      <Mouse />
    </div>
  )
}
