import { DEBUG } from "@components/background/tiles/config"
import { Tiles } from "@components/background/tiles/Tiles"
import { TilesState } from "@components/background/tiles/TilesState"
import { EventMicroCard } from "@components/cards/EventMicroCard"
import { StaggerTransitionRoot } from "@components/motion/Transition"
import { Footer } from "@components/site/Footer"
import { useRouter } from "eddev/routing"
import { defineView } from "eddev/views"
import { Suspense, useEffect, useMemo, useRef, useState } from "react"
import { twMerge } from "tailwind-merge"
import { ref } from "valtio"

export default defineView("front-page", (props) => {
  const router = useRouter()
  TilesState.active = true
  TilesState.mode = "playground"
  TilesState.contentFitRef.current = null
  TilesState.items = props.todaysTopAwards ?? []
  TilesState.events = props.events?.nodes ?? []
  TilesState.router = ref(router)

  const safezoneSensor = useRef<HTMLDivElement>(null!)
  useEffect(() => {
    const el = safezoneSensor.current!
    const update = () => {
      const w = window.innerWidth
      const h = el.offsetHeight
      if (w < 700) {
        TilesState.safezone.left = 0
        TilesState.safezone.top = 80
        TilesState.safezone.bottom = h + TilesState.safezone.top - 50
        TilesState.safezone.right = w
        return
      }
      const bounds = el.getBoundingClientRect()
      TilesState.safezone.left = bounds.left
      TilesState.safezone.top = bounds.top
      TilesState.safezone.bottom = bounds.bottom
      TilesState.safezone.right = bounds.right
      if (DEBUG) console.log("BOUNDS", bounds)
    }
    const ro = new ResizeObserver(update)
    update()
    ro.observe(el)
    return () => {
      ro.disconnect()
    }
  }, [])

  const [hoverEvent, setHoverEvent] = useState(-1)
  const [hovering, setHovering] = useState(false)
  useEffect(() => {
    if (!hovering) {
      const timer = setTimeout(() => {
        setHoverEvent(-1)
      }, 300)
      return () => clearTimeout(timer)
    }
  }, [hovering])

  const events = useMemo(() => {
    return (
      props.events?.nodes
        ?.sort((a, b) => {
          let aDate = a.eventInfo?.featured ? "" : a.dates[0].startDate
          let bDate = b.eventInfo?.featured ? "" : b.dates[0].startDate
          return aDate?.localeCompare(bDate ?? "") ?? 0
        })
        .slice(0, 4) ?? []
    )
  }, [])

  return (
    <StaggerTransitionRoot>
      <div>
        <div className="h-[calc(100svh_-150px)] md:min-h-pageMin">
          {/* Tiles app */}
          <div className="mt-0 w-full top-0 left-0 absolute h-[calc(100svh)] md:h-[100vh]">
            <div id="background" className="absolute inset-0 select-none *:select-none *:pointer-events-none z-[0] overflow-hidden" data-transition="fade">
              <Suspense fallback={null}>
                <Tiles />
              </Suspense>
              <div className="absolute inset-0 top-headerHeight pointer-events-none">
                <div className="grid-auto h-full pt-5 pb-[80px] md:pt-5 md:pb-[100px]">
                  <div ref={safezoneSensor} className={twMerge("col-span-12 md:col-span-10 md:col-start-3 h-full", DEBUG ? "outline" : "")}></div>
                </div>
              </div>
            </div>
          </div>
          {/* Events (Desktop) */}
          <div className="h-pageMin relative pointer-events-none hidden md:grid-auto">
            <div className="col-span-2">
              {events.length! > 0 && (
                <>
                  <h3 data-transition="fade" className="type-title-xs-medium mt-4 mb-5">
                    What's on:
                  </h3>
                  <div data-transition="children/fade" className="stack-y-3 pointer-events-auto relative">
                    {events.map((event, i) => (
                      <EventMicroCard
                        key={event.uri}
                        mode="stacked"
                        {...event}
                        hovering={hoverEvent === i}
                        onHover={(hovering) => {
                          if (hovering) {
                            setHoverEvent(i)
                            setHovering(true)
                          } else {
                            setHovering(false)
                          }
                        }}
                      />
                    ))}
                  </div>
                </>
              )}
            </div>
          </div>
        </div>
        {/* Events (Mobile) */}
        <div className="block md:hidden pb-8">
          {events.length! > 0 && (
            <div data-transition="children/fade" className="flex gap-4 overflow-auto snap-x snap-proximity hide-scrollbars scroll-pl-4 pl-4 pr-4 pointer-events-auto relative">
              {events.map((event, i) => (
                <div className="w-[min(70vw,_300px)] flex-none snap-start" key={event.uri}>
                  <EventMicroCard
                    mode="row"
                    {...event}
                    hovering={hoverEvent === i}
                    onHover={(hovering) => {
                      if (hovering) {
                        setHoverEvent(i)
                        setHovering(true)
                      } else {
                        setHovering(false)
                      }
                    }}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
        <div className="block md:hidden">
          <Footer />
        </div>
      </div>
    </StaggerTransitionRoot>
  )
})
