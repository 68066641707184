import Matter from "matter-js"
import { useEffect, useRef } from "react"
import { useWindowSize } from "react-use"
import { TilesState } from "./TilesState"
import { subscribe } from "valtio"

export const Walls = () => {
  const { width, height } = useWindowSize()
  // Matter.js bodies are anchored in the centre, so size the walls x2
  const wallScales = useRef({
    top: { x: 1000, y: 10 },
    bottom: { x: 1000, y: 10 },
    left: { x: 10, y: 1000 },
    right: { x: 10, y: 1000 }
  })
  const walls: { [key: string]: any } = useRef({
    top:
      Matter.Bodies.rectangle(500, 0, wallScales.current.top.x, wallScales.current.top.y, { isStatic: true }),
    bottom:
      Matter.Bodies.rectangle(500, 1000, wallScales.current.bottom.x, wallScales.current.bottom.y, { isStatic: true }),
    left:
      Matter.Bodies.rectangle(0, 500, wallScales.current.left.x, wallScales.current.left.y, { isStatic: true }),
    right:
      Matter.Bodies.rectangle(1000, 500, wallScales.current.right.x, wallScales.current.right.y, { isStatic: true })
  })

  useEffect(() => {
    for (const wall in walls.current) {
      Matter.World.add(TilesState.engine.world, walls.current[wall])
    }
    return () => {
      for (const wall in walls.current) {
        Matter.World.remove(TilesState.engine.world, walls.current[wall])
      }
    }
  }, [])

  useEffect(() => {
    return subscribe(TilesState, () => {
      const safezone = TilesState.safezone
      const w = window.innerWidth
      const h = window.screen.availHeight
      const hw = w / 2
      const hh = h / 2

      const topW = w * 2
      const topH = safezone.top * 2
      const bottomW = w * 2
      const bottomH = (h - safezone.bottom) * 2
      const leftW = safezone.left * 2
      const leftH = h * 2
      const rightW = (w - safezone.right) * 2
      const rightH = h * 2

      Matter.Body.setPosition(walls.current.top, { x: hw, y: safezone.top - (topH / 2) })
      Matter.Body.setPosition(walls.current.bottom, { x: hw, y: safezone.bottom + (bottomH / 2) })
      Matter.Body.setPosition(walls.current.left, { x: safezone.left - (leftW / 2), y: hh })
      Matter.Body.setPosition(walls.current.right, { x: safezone.right + (rightW / 2), y: hh })

      Matter.Body.scale(walls.current.top, topW / wallScales.current.top.x, topH / wallScales.current.top.y)
      Matter.Body.scale(walls.current.bottom, bottomW / wallScales.current.bottom.x, bottomH / wallScales.current.bottom.y)
      Matter.Body.scale(walls.current.left, leftW / wallScales.current.left.x, leftH / wallScales.current.left.y)
      Matter.Body.scale(walls.current.right, rightW / wallScales.current.right.x, rightH / wallScales.current.right.y)
      wallScales.current.top.x = topW
      wallScales.current.top.y = topH
      wallScales.current.bottom.x = bottomW
      wallScales.current.bottom.y = bottomH
      wallScales.current.left.x = leftW
      wallScales.current.left.y = leftH
      wallScales.current.right.x = rightW
      wallScales.current.right.y = rightH
    })
  }, [])

  return null
}
